import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { UPDATE_SEARCH_KEY, GET_ALL_SEARCH_RESULTS } from '@dstv-web-leanback/dstv-frontend-services'
import styles from './SearchInput.module.scss'
import searchIcon from '../../../assets/images/search_active.png'

export function SearchInput(props) {
	const dispatch = useDispatch()
	const keyboardState = useSelector((state) => state.keyboard)
	const searchState = useSelector((state) => state.search)

	useEffect(() => {
		searchState.searchKey &&
			searchState.searchKey.length > 1 &&
			dispatch(GET_ALL_SEARCH_RESULTS({ searchKey: searchState.searchKey }))
	}, [searchState.searchKey])

	useEffect(() => {
		dispatch(UPDATE_SEARCH_KEY({ searchKey: keyboardState.value }))
	}, [keyboardState.value])

	return (
		<div className={`${styles['TvSearchBar__search_bar']} ${styles.active}`}>
			<img alt="search" className={styles['TvSearch_icon']} src={searchIcon} />
			<span className={styles['search_input']}>
				<span className={styles['search_placeholder']}>
					<input
						type="text"
						className={styles['center']}
						placeholder="What are you looking for?"
						value={keyboardState.value}
						readOnly
					/>
				</span>
			</span>
		</div>
	)
}
export default SearchInput
