import styles from './SettingsConfirmationBox.module.scss'
import { useEffect } from 'react'
import { Button } from '@dstv-web-leanback/dstv-frontend-components'

import { useFocusable, FocusContext } from '@connected-video-web/norigin-spatial-navigation'

export function SettingsConfirmationBox({ mainHeading, subHeading, body, buttons, handleBack }) {
	const { focusKey, focusSelf, ref } = useFocusable({
		isFocusBoundary: true,
		onBackPress: handleBack,
	})

	useEffect(() => {
		focusSelf()
	})

	return (
		<FocusContext.Provider value={focusKey}>
			<div ref={ref} className={styles.confirmation_box}>
				<div className={styles.content_wrapper}>
					{mainHeading && <h2 className={styles.main_heading}>{mainHeading}</h2>}
					{subHeading && <h3 className={styles.sub_heading}>{subHeading}</h3>}
					{body && <p className={styles.body}>{body}</p>}
				</div>
				<div className={styles.buttons_wrapper}>
					{buttons.map((button, index) => {
						return <Button key={index} id={button.title} title={button.title} onSelect={button.action} />
					})}
				</div>
			</div>
		</FocusContext.Provider>
	)
}
export default SettingsConfirmationBox
