import { createSlice } from '@reduxjs/toolkit'
import { mergeMap } from 'rxjs/operators'
import { ofType } from 'redux-observable'
import { of } from 'rxjs'

import { errorResponse, HTTP } from '../../utils/httpHelper'
import { getNoCacheHeaders, handleUnauthorized } from '../../utils/authHelper'
import { ENV } from '../../utils/environment'

export const PROXIMITY_FEATURE_KEY = 'proximity'

/*
 * Create our slice
 */
export const proximitySlice = createSlice({
	name: PROXIMITY_FEATURE_KEY,
	initialState: {},
	reducers: {
		GET_PROXIMITY_STATUS: (state) => {
			delete state.error
			delete state.serverError
			state.loading = true
		},
		GET_PROXIMITY_STATUS_SUCCESS: (state, action) => {
			state.proximity = action.payload.data
			state.loading = false
		},
		GET_PROXIMITY_STATUS_ERROR: (state, action) => {
			state.error = action.payload.error
			state.serverError = action.payload.serverError
			state.loading = false
		},
		CLEAR_PROXIMITY_STATUS: (state) => {
			state.proximity = null
		},
	},
})

/*
 * Export reducer for store configuration.
 */
export const proximityReducer = proximitySlice.reducer

/*
 * Export actions
 */
export const {
	GET_PROXIMITY_STATUS,
	GET_PROXIMITY_STATUS_SUCCESS,
	GET_PROXIMITY_STATUS_ERROR,
	CLEAR_PROXIMITY_STATUS,
} = proximitySlice.actions

/*
 * Set up the redux-observable epic
 */
export const proximityEpic = (action$) =>
	action$.pipe(ofType(GET_PROXIMITY_STATUS.type), mergeMap(proximityService(action$)))

/*
 * Do API calls
 */
const proximityService = (action$) => (action) => {
	switch (action.type) {
		case GET_PROXIMITY_STATUS.type: {
			const endpointFromMenu = ENV.GET_PROXIMITY_STATUS
			return HTTP.GET(endpointFromMenu, getNoCacheHeaders(), proximitySuccess(action), proximityError(action), true)
		}
	}
}

/*
 * Dispatch actions based on API responses
 */
const proximitySuccess = () => (response) => {
	return { type: GET_PROXIMITY_STATUS_SUCCESS.type, payload: { data: response } }
}

const proximityError = (action) => (response) => {
	return of(
		handleUnauthorized(
			response,
			{
				type: GET_PROXIMITY_STATUS_ERROR.type,
				payload: errorResponse(response, action),
			},
			action
		)
	)
}
