import { createSlice } from '@reduxjs/toolkit'
import { mergeMap, filter } from 'rxjs/operators'
import { of } from 'rxjs'

import { ENV } from '../../utils/environment'
import { errorResponse, HTTP, successResponse } from '../../utils/httpHelper'
import { getDefaultHeaders } from '../../utils/authHelper'

export const AUTH_FEATURE_KEY = 'auth'

/*
 * Create our slice
 */
export const authSlice = createSlice({
	name: AUTH_FEATURE_KEY,
	initialState: {},
	reducers: {
		LOGIN: (state, action) => {
			state.loading = true
			delete state.error
			delete state.serverError
		},
		LOGIN_SUCCESS: (state, action) => {
			state.loading = false
			state.data = action.payload.data
		},
		LOGIN_ERROR: (state, action) => {
			state.error = action.payload.error
			state.serverError = action.payload.serverError || action.payload
			state.loading = false
		},
		LOGOUT: (state, action) => {
			state = {}
		},
	},
})

/*
 * Export reducer for store configuration.
 */
export const authReducer = authSlice.reducer

/*
 * Export actions
 */
export const { LOGIN, LOGIN_SUCCESS, LOGIN_ERROR, LOGOUT } = authSlice.actions

/*
 * Set up the redux-observable epic
 */
export const authEpic = (action$) => action$.pipe(filter(LOGIN.match), mergeMap(authService(action$)))

/*
 * Do API calls
 */
const authService = (action$) => (action) => {
	return HTTP.POST(ENV.DEVICE_REGISTRATION, action.payload, getDefaultHeaders(), loginSuccess, loginError(action), true)
}

/*
 * Dispatch actions based on API responses
 */
const loginSuccess = (response) => {
	return {
		type: LOGIN_SUCCESS.type,
		payload: successResponse(response.response || response),
	}
}
const loginError = (action) => (response) => of({ type: LOGIN_ERROR.type, payload: errorResponse(response, action) })
