import { useFocusable } from '@connected-video-web/norigin-spatial-navigation'
import styles from './SidePanelItem.module.scss'

export const SidePanelItem = ({ text, index, onSelect }) => {
	const { focusKey, ref, focused } = useFocusable({
		focusKey: `SIDE_PANEL_ITEM_${index}`,
		onEnterPress: onSelect,
	})

	return (
		<div ref={ref} className={[styles.sidepanel_item, focused ? styles.focus : ''].join(' ')}>
			<div>{text}</div>
		</div>
	)
}

export default SidePanelItem
