import { createSlice } from '@reduxjs/toolkit'
import { mergeMap, filter } from 'rxjs/operators'
import { of } from 'rxjs'

import { ENV, platformId } from '../../utils/environment'
import { errorResponse, HTTP } from '../../utils/httpHelper'
import { getHeaders, handleUnauthorized } from '../../utils/authHelper'
import { getDeviceID } from '../../utils/storageHelper'
import { secureLocalStorage } from '../../utils/secureStorage'
import { ErrorMessages } from '../../utils/errorCodeHelper'
import { PLAY } from '../player/player.slice'

import { DEVICE_ID } from '../../utils/constants'

import {
	getDeviceType,
	getOsName,
	getOSVersion,
	getDrm,
	getHdcpComplaince,
	getSecurityLevel,
} from '../../utils/platform'

export const ENTITLEMENT_FEATURE_KEY = 'entitlement'

const initialState = { loading: true, error: null, data: null }
/*
 * Create our slice
 */
export const entitlementSlice = createSlice({
	name: ENTITLEMENT_FEATURE_KEY,
	initialState: initialState,
	reducers: {
		GET_ENTITLEMENT: (state, action) => {
			delete state.error
			delete state.serverError
			state.loading = true
		},
		GET_ENTITLEMENT_SUCCESS: (state, action) => {
			state.loading = false
			state.data = { ...action.payload }
		},
		GET_ENTITLEMENT_ERROR: (state, action) => {
			state.loading = false
			state.error = getErrorMessageType(action.payload) ?? action.payload.error
			state.serverError = action.payload.serverError
		},
		extraReducers: (builder) => {
			builder.addCase(PLAY.type, (state) => {
				delete state.error
				delete state.serverError
			})
		},
	},
})

/*
 * Export reducer for store configuration.
 */
export const entitlementReducer = entitlementSlice.reducer

/*
 * Export actions
 */
export const { GET_ENTITLEMENT, GET_ENTITLEMENT_SUCCESS, GET_ENTITLEMENT_ERROR } = entitlementSlice.actions

/*
 * Set up the redux-observable epic
 */
export const entitlementEpic = (action$) =>
	action$.pipe(filter(GET_ENTITLEMENT.match), mergeMap(entitlementService(action$)))

/*
 * Do API calls
 */
const entitlementService = (action$) => (action) => {
	switch (action.type) {
		case GET_ENTITLEMENT.type: {
			let data = {
				device_id: getDeviceID(),
				device_type: getDeviceType(),
				session_type: 'streaming',
				device_name: 'Leanback Device',
				platform_id: platformId,
				os: getOsName(),
				os_version: getOSVersion(),
				drm: getDrm(),
				hdcp: window.playerRetry ? 'NA' : getHdcpComplaince(),
				security_level: window.playerRetry ? 'NA' : getSecurityLevel(),
				hdcp_max: localStorage.getItem('PRODUCT_TYPE') === 'CHROME_CAST' ? 'HDCP-2.3' : '',
			}

			return HTTP.POST(ENV.GET_ENTITLEMENT, data, getHeaders(), entitlementResponse, getEntitlementError(action), true)
		}
	}
}

/*
 * Dispatch actions based on API responses
 */
const entitlementResponse = (response) => {
	let payload = {
		irdetoSession: {
			sessionId: (response?.response || response)?.session,
			sessionExpiryDateTime: (response?.response || response)?.expiry_date,
			irdetoCtrEnabled: true,
		},
		decoded: JSON.parse(atob((response?.response || response)?.session.split('.')[1])),
		ucp_filter: (response?.response || response)?.ucp_filter,
	}

	secureLocalStorage.setItem('playback-entitlement', JSON.stringify(payload))

	return {
		type: GET_ENTITLEMENT_SUCCESS.type,
		payload: payload,
	}
}

const getEntitlementError = (action) => (response) => {
	return of(
		handleUnauthorized(
			response,
			{
				type: GET_ENTITLEMENT_ERROR.type,
				payload: errorResponse(response, action),
			},
			action
		)
	)
}

const getErrorMessageType = (payload) => {
	const errorReason = payload?.serverError?.type

	if (errorReason?.includes('device-limit-reached')) {
		return ErrorMessages.getErrorMessageByName('deviceLimit')
	}
}
