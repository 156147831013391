import { useEffect, useState } from 'react'
import { FocusContext, useFocusable } from '@connected-video-web/norigin-spatial-navigation'
import { ProfileImage } from '../../core/ProfileImage/ProfileImage'
import styles from './AvatarList.module.scss'

export function AvatarList({ avatars, setTitleKey, setSelectedAvatarId, type, onSelectItem }) {
	const { focusKey, ref, focusSelf } = useFocusable({
		trackChildren: true,
		focusKey: 'AvatarList',
	})

	const [carouselScroll, setCarouselScroll] = useState(0)

	useEffect(() => {
		setTitleKey('avatar')
	}, [setTitleKey])

	useEffect(() => {
		focusSelf()
	}, [focusSelf])

	const changeAvatarListFocus = (focusedRef) => {
		if (focusedRef?.current) {
			setCarouselScroll(focusedRef?.current?.offsetLeft - Number.parseInt(getComputedStyle(ref.current).paddingLeft))
		}
	}

	return (
		<FocusContext.Provider value={focusKey}>
			<section ref={ref} className={styles.avatar_list} style={{ transform: `translate3d(-${carouselScroll}px,0,0)` }}>
				{avatars &&
					avatars.map((avatar, index) => {
						return (
							avatar && (
								<ProfileImage
									index={index}
									key={avatar.id}
									imgKey={avatar.id}
									alias={avatar.id}
									avatarUri={avatar.uri}
									imageType="avatar"
									avatarProps={{ index, total: avatars.length }}
									onSelectItem={() => {
										onSelectItem(avatar.id, avatar.uri)
									}}
									onItemFocused={(ref) => {
										changeAvatarListFocus(ref)
										setSelectedAvatarId(avatar.id)
										if (type !== 'new') {
											setTitleKey('avatar')
										}
									}}
								/>
							)
						)
					})}
			</section>
		</FocusContext.Provider>
	)
}
export default AvatarList
