import { createSlice } from '@reduxjs/toolkit'
import { mergeMap } from 'rxjs/operators'
import { ofType } from 'redux-observable'
import { of } from 'rxjs'
import moment from 'moment'

import { SET_ACTIVE_PROFILE_ID } from '../profile/profile.slice'

import { ENV } from '../../utils/environment'
import { errorResponse, HTTP } from '../../utils/httpHelper'
import { getHeaders, handleUnauthorized } from '../../utils/authHelper'
import { getEndpointsFromMenu } from '../../utils/storageHelper'
import { transformMenuItems } from '../../utils/transformHelper'
import { fallbackChannelEvent, HOUR_WIDTH } from '../../utils/constants'

export const CHANNELS_FEATURE_KEY = 'channels'

/*
 * Create our slice
 */

const initialState = {
	channels: [],
	channel_index: 0,
	eventsLoading: false,
	channelsLoading: true,
}

export const channelsSlice = createSlice({
	name: CHANNELS_FEATURE_KEY,
	initialState: initialState,
	reducers: {
		CLEAR_CHANNELS: (state) => {
			Object.assign(state, initialState)
		},
		GET_CHANNELS: (state) => {
			delete state.error
			delete state.serverError
			state.loading = true
			state.channelsLoading = true
		},
		GET_CHANNELS_SUCCESS: (state, action) => {
			state.loading = false
			state.channelsLoading = false
			state.error = null
			state.channels = action.payload.channels
		},
		GET_DAY_EVENTS: (state) => {
			delete state.error
			delete state.serverError
			state.loading = true
			state.eventsLoading = true
		},
		GET_DAY_EVENTS_FALLBACK: (state, action) => {
			let targetIndex = state.channels.findIndex((channel) => channel.tag === action.payload.channelTag)
			state.loading = false
			state.error = null
			if (state.channels?.[targetIndex]) {
				state.channels[targetIndex].events = action.payload.epg
			}
		},
		GET_DAY_EVENTS_SUCCESS: (state, action) => {
			let targetIndex = state.channels.findIndex((channel) => channel.tag === action.payload.channelTag)
			if (state.channels?.[targetIndex]) {
				state.channels[targetIndex].epgCalled = true

				// Add some channels details from the state to the epg
				action.payload.epg.forEach((program) => {
					program.channelName = state.channels[targetIndex].name
					program.channelNumber = state.channels[targetIndex].number
					program.channelCategory = state.channels[targetIndex].category
				})

				state.channels[targetIndex].events = action.payload.epg
			}
			state.loading = false
			state.eventsLoading = false
			state.error = null
		},
		GET_LIVE_TV_SECTIONS: (state) => {
			delete state.error
			delete state.serverError
			state.loading = true
		},
		GET_LIVE_TV_SECTIONS_SUCCESS: (state) => {
			state.loading = false
		},
		CHANNELS_ERROR: (state, action) => {
			state.error = action.payload.error
			state.serverError = action.payload.serverError
			state.loading = false
		},
		SET_CHANNEL_INDEX: (state, action) => {
			state.channel_index = action.payload.index
		},
	},
	extraReducers: (builder) => {
		builder.addCase(SET_ACTIVE_PROFILE_ID.type, (state) => {
			Object.assign(state, initialState)
		})
	},
})
/*
 * Export reducer for store configuration.
 */
export const channelsReducer = channelsSlice.reducer

/*
 * Export actions
 */
export const {
	CHANNELS_ERROR,
	CLEAR_CHANNELS,
	GET_CHANNELS_SUCCESS,
	GET_CHANNELS,
	GET_DAY_EVENTS_FALLBACK,
	GET_DAY_EVENTS_SUCCESS,
	GET_DAY_EVENTS,
	GET_LIVE_TV_SECTIONS_SUCCESS,
	GET_LIVE_TV_SECTIONS,
	SET_CHANNEL_INDEX,
} = channelsSlice.actions

/*
 * Set up the redux-observable epic
 */
export const channelsEpic = (action$) =>
	action$.pipe(
		ofType(GET_CHANNELS.type, GET_DAY_EVENTS.type, GET_LIVE_TV_SECTIONS.type),
		mergeMap(channelsService(action$))
	)

/*
 * Do API calls
 */
const channelsService = (action$) => (action) => {
	switch (action.type) {
		case GET_CHANNELS.type: {
			return HTTP.GET_WITH_CANCEL(
				action.payload.url,
				getHeaders(),
				channelsSuccess(),
				channelsError(action),
				true,
				action$.pipe(ofType(GET_CHANNELS.type))
			)
		}
		case GET_DAY_EVENTS.type: {
			let dateStr = moment().format('YYYY[-]MM[-]DD')
			let endpoint = ENV.GET_DAY_EVENTS.replace('{CHANNEL_TAG}', action.payload.channelTag).replace('{DATE}', dateStr)

			return HTTP.GET_WITH_CANCEL_AND_TIMEOUT(
				endpoint,
				getHeaders(),
				getDayEventsSuccess(action),
				getDayEventsError(action),
				true,
				action$.pipe(ofType(GET_CHANNELS.type)),
				5000
			)
		}
		case GET_LIVE_TV_SECTIONS.type: {
			let endpointsFromMenu = getEndpointsFromMenu(action.payload.category)
			return HTTP.GET(
				endpointsFromMenu,
				getHeaders(),
				liveTVSectionsSuccess(action.payload.category),
				channelsError(action)
			)
		}
	}
}

/*
 * Dispatch actions based on API responses
 */
const channelsSuccess = () => (response) => {
	let channels = []

	response.items.map((item) => {
		channels.push({
			epgCalled: false,
			events: [],
			logo: item.channelLogoPaths.XLARGE,
			number: item.number,
			name: item.name,
			playerUrl: item.streams[0].playerUrl,
			tag: item.id,
			uuid: item.notificationId,
			category: item.genres.join('|'),
		})
	})

	return { type: GET_CHANNELS_SUCCESS.type, payload: { channels }, isLoading: false }
}

const liveTVSectionsSuccess = () => (response) => {
	return { type: GET_LIVE_TV_SECTIONS_SUCCESS.type, payload: { menuItems: transformMenuItems(response) } }
}

const getDayEventsSuccess = (action) => (response) => {
	let epg = []

	response.map((event, index) => {
		let start = moment(event.startDateTime)
		let end = moment(event.endDateTime)
		epg.push({
			...event,
			index: index,
			channelTag: event.channelTag,
			channelUuid: action.payload.uuid,
			description: event.longSynopsis,
			genre: event.primaryGenre,
			image: event.thumbnailImagePaths?.XLARGE,
			start: start,
			end: end,
			position: {
				left: (start.hours() + start.minutes() / 60) * HOUR_WIDTH,
				width: (end.diff(start, 'minutes') / 60) * HOUR_WIDTH,
			},
			timeShiftUrl: 'https://i-preprod-cache.akamaized.net/USL07/HDT/HDT.isml/.mpd?begin=1710798900',
		})
	})

	return { type: GET_DAY_EVENTS_SUCCESS.type, payload: { channelTag: action.payload.channelTag, epg } }
}

const getDayEventsError = (action) => (response) => {
	let epg = []

	let fallbackEpg = Array.from({ length: 24 }, (_, index) => {
		let start = moment(new Date().setHours(index, 0, 0, 0))
		let end = moment(new Date().setHours(index + 1, 0, 0, 0))

		epg.push({
			...fallbackChannelEvent,
			index: index,
			channelTag: action.payload.channelTag,
			channelUuid: action.payload.uuid,
			description: fallbackChannelEvent.longSynopsis,
			genre: fallbackChannelEvent.primaryGenre,
			image: fallbackChannelEvent.thumbnailImagePaths?.XLARGE,
			start: start,
			end: end,
			position: {
				left: (start.hours() + start.minutes() / 60) * HOUR_WIDTH,
				width: (end.diff(start, 'minutes') / 60) * HOUR_WIDTH,
			},
		})
	})

	return of(
		handleUnauthorized(
			response,
			{
				type: GET_DAY_EVENTS_FALLBACK.type,
				payload: { channelTag: action.payload.channelTag, epg },
			},
			action
		)
	)
}

const channelsError = (action) => (response) => {
	return of(
		handleUnauthorized(
			response,
			{
				type: `channels/CHANNELS_ERROR`,
				payload: errorResponse(response, action),
			},
			action
		)
	)
}
