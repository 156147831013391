import { useFocusable } from '@connected-video-web/norigin-spatial-navigation'
import styles from './SkipArrow.module.scss'

export function SkipArrow({ direction, handleArrowPress, icon, skipAmount, skipDirection, skipping }) {
	const { ref } = useFocusable({
		onArrowPress: (direction) => {
			handleArrowPress(direction)
		},
	})

	return (
		<div
			ref={ref}
			className={`${styles.skip_arrow} ${styles[direction]} ${
				direction === skipDirection && skipping && styles.active
			}`}
		>
			<img src={icon} />
			<h2>{skipAmount}</h2>
		</div>
	)
}

export default SkipArrow
