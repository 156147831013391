import styles from './Login.module.scss'
import loginLogo from '../../../assets/images/smarttv/dstv_login_logo_round.png'
import { loginLabels } from '../../../utils/content'
export function Login(props) {
	const { userCode } = props
	let labels = loginLabels
	return (
		userCode && (
			<div className={styles.login_screen}>
				<img alt="Dstv logo" className={styles.login_logo} src={loginLogo} />
				<div className={styles.url_line}>{labels.urlLine}</div>
				{labels?.url && <div className={styles.url}>{labels.url}</div>}
				<div className={styles.enter_code_line}>{labels.codeLine}</div>
				{window.location.href.indexOf('staging') !== -1 && (
					<span className={styles.domain}>{window.location.href}</span>
				)}
				<div className={styles.code_line}>{userCode}</div>
				<div className={styles.enter_siginin_line}>{labels.codeLineSignin}</div>
			</div>
		)
	)
}
export default Login
