import { useFocusable, FocusContext } from '@connected-video-web/norigin-spatial-navigation'

import { Button } from '@dstv-web-leanback/dstv-frontend-components'

import styles from './DialogButtonGroup.module.scss'

export function DialogButtonGroup({ actionButtons, orientation = 'horizontal' }) {
	const { ref, focusKey } = useFocusable()

	return (
		<FocusContext.Provider value={focusKey}>
			<div ref={ref} className={`${styles.dialog_buttons_group} ${styles[orientation]}`}>
				{actionButtons.map((item) => (
					<Button
						focusKeyParam={item.focusKeyParam}
						id={item.title}
						key={item.title}
						onItemFocus={item.onItemFocus}
						onSelect={item.action}
						title={item.title}
					/>
				))}
			</div>
		</FocusContext.Provider>
	)
}
export default DialogButtonGroup
