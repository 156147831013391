import { useEffect, useRef, useState } from 'react'

import styles from './ChannelBlock.module.scss'

export function ChannelBlock({ channel, getDayEvents }) {
	const { logo, tag, uuid, number } = channel

	const channelLogo = useRef()

	useEffect(() => {
		if (!channel?.epgCalled) {
			let io = new IntersectionObserver((entries) => {
				if (entries[0].isIntersecting) {
					getDayEvents(tag, uuid)
				}
			})

			if (channelLogo.current) {
				io.observe(channelLogo.current)
			}

			return () => {
				if (channelLogo.current) {
					io.unobserve(channelLogo.current)
				}
			}
		}
	}, [channel.uuid, channel.epgCalled])

	return (
		<div ref={channelLogo} className={styles.channel_item}>
			<h5>{number}</h5>
			<img src={logo} />
		</div>
	)
}
export default ChannelBlock
