import { ShakaAdapter } from 'bitmovin-analytics'
import { useCallback, useEffect, useRef, useState } from 'react'
import { BITMOVIN_ANALYTICS_KEY } from '../utils/environment'
import { getUserID } from '../utils/storageHelper'

const analyticsConfig = {
	key: BITMOVIN_ANALYTICS_KEY,
	isLive: false,
	videoId: 'videoId',
}
/**
 * 
 * @param {*} config 
 * 
export interface CollectorConfig {
	backendUrl?: string;
	enabled?: boolean;
	cookiesEnabled?: boolean;
	cookiesDomain?: string;
	origin?: string;
}
export interface AnalyticsConfig {
	debug?: boolean | AnalyticsDebugConfig;
	key?: string;
	playerKey?: string;
	player?: string;
	cdnProvider?: string;
	videoId?: string;
	title?: string;
	userId?: string;
    customUserId?: string;
    experimentName?: string;
	isLive?: boolean;
	config?: CollectorConfig;
}
 */
export const useBitmovinAnalytics = (config = {}) => {
	let userId = getUserID()
	config = { ...config, ...analyticsConfig, userId }

	const analytics = useRef(null)

	const [cfg, setCFG] = useState(config)

	const initAnalytics = useCallback(
		(config, player) => {
			if (!analytics.current) {
				analytics.current = new ShakaAdapter(config, player)
			}
		},
		[cfg, analytics]
	)

	const updateConfig = useCallback(
		(new_cfg) => {
			const updateCFG = { ...cfg, ...new_cfg }
			updateCFG.key = BITMOVIN_ANALYTICS_KEY
			if (analytics.current) {
				analytics.current.sourceChange(updateCFG)
			}
			setCFG(updateCFG)
		},
		[cfg, setCFG, analytics]
	)

	return { initAnalytics, bitmovinConfig: cfg, updateConfig, analytics: analytics.current }
}
