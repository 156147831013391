import { createSlice } from '@reduxjs/toolkit'
import { mergeMap } from 'rxjs/operators'
import { of } from 'rxjs'
import { ofType } from 'redux-observable'

import { ENV } from '../../utils/environment'
import { errorResponse, HTTP } from '../../utils/httpHelper'
import { transformAppsResponse } from './helper'
import { getHeaders, handleUnauthorized } from '../../utils/authHelper'

export const APP_LIST_KEY = 'appList'

export const appListSlice = createSlice({
	name: APP_LIST_KEY,
	initialState: {},
	reducers: {
		GET_APPLIST: (state, action) => {
			state.isLoading = true
			delete state.error
			delete state.serverError
		},
		GET_APPLIST_SUCCESS: (state, action) => {
			state.isLoading = false
			state.items = transformAppsResponse(action.payload.response, 'apps')
		},
		GET_APPLIST_ERROR: (state, action) => {
			state.error = action.payload.error
			state.serverError = action.payload.serverError
			state.items = []
			state.isLoading = false
		},
	},
})

/*
 * Export reducer for store configuration.
 */
export const applisting = appListSlice.reducer

export const { GET_APPLIST, GET_APPLIST_SUCCESS, GET_APPLIST_ERROR } = appListSlice.actions

/*
 * Set up the redux-observable epic
 */
export const appListEpic = (action$) => action$.pipe(ofType(GET_APPLIST.type), mergeMap(appListService(action$)))

/*
 * Do API calls
 */
const appListService = (action$) => (action) => {
	const { payload } = action
	switch (action.type) {
		case GET_APPLIST.type: {
			return HTTP.GET(ENV.GET_APPLIST, getHeaders(), appListSuccess(payload), appListError(action), true)
		}
	}
}

export const appListSuccess = (_action) => (response) => {
	return { type: GET_APPLIST_SUCCESS.type, payload: { response } }
}

const appListError = (action) => (response) => {
	return of(
		handleUnauthorized(
			response,
			{
				type: GET_APPLIST_ERROR.type,
				payload: errorResponse(response, action),
			},
			action
		)
	)
}
