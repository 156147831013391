import { useState } from 'react'
import { setFocus, useFocusable } from '@connected-video-web/norigin-spatial-navigation'

import iconForward from '../../../assets/images/icons/icon-player-forward.svg'
import iconRewind from '../../../assets/images/icons/icon-player-rewind.svg'

import styles from './PlayerProgressBar.module.scss'

export function PlayerProgressBar({ focusable, handleSeek, progress, seeking }) {
	const [seekDirection, setSeekDirection] = useState()

	const { ref, focused } = useFocusable({
		focusable: focusable ? true : false,
		onArrowPress: (direction, _props, _details) => {
			switch (direction) {
				case 'left':
				case 'right':
					setSeekDirection(direction)
					handleSeek(direction)
					break
				case 'down':
					setFocus('PLAY_PAUSE')
					break
				default:
					break
			}
		},
	})

	return (
		<div ref={ref} className={`${styles.progress_bar} ${focused ? styles.focus : ''}`}>
			<div className={styles.progress} style={{ width: `${progress}%` }}></div>
			<div style={{ marginLeft: `${progress}%` }}>
				{focused && (
					<span className={styles.player_state_indicator}>
						{seeking && <img src={seekDirection === 'right' ? iconForward : iconRewind} />}
					</span>
				)}
			</div>
		</div>
	)
}

export default PlayerProgressBar
