import { createSlice } from '@reduxjs/toolkit'
import { of } from 'rxjs'
import { mergeMap } from 'rxjs/operators'
import { errorResponse, HTTP, successResponse } from '../../utils/httpHelper'
import { getHeaders, handleUnauthorized } from '../../utils/authHelper'
import { ofType } from 'redux-observable'
import { getEndpointsFromMenu } from '../../utils/storageHelper'
import { getCardPosterImageURL } from '../../utils/transformHelper'

export const WATCH_LIST_FEATURE_KEY = 'watchlist'

export const initialMylistState = {}
export const watchlistSlice = createSlice({
	name: WATCH_LIST_FEATURE_KEY,
	initialState: {
		data: null,
		loading: true,
	},
	reducers: {
		WATCH_LIST: (state) => {
			state.error = null
			state.loading = true
		},
		WATCH_LIST_PAGING: (state) => {
			state.error = null
		},
		WATCH_LIST_PAGING_SUCCESS: (state, action) => {
			state.loading = false
			if (action.payload?.response?.items.length) {
				state.data.page = action.payload?.response?.page
				state.data.items = [...state.data.items, ...action.payload.response.items]
				state.data?.items?.map((item) => [(item.poster_image = getCardPosterImageURL(item))])
			}
		},
		WATCH_LIST_SUCCESS: (state, action) => {
			state.loading = false
			state.page = action.payload?.response?.page
			state.data = action.payload.data
			state.data?.items?.map((item) => [(item.poster_image = getCardPosterImageURL(item))])
		},
		WATCH_LIST_ERROR: (state, action) => {
			state.data = null
			state.error = action.payload.error
			state.serverError = action.payload.serverError
			state.loading = false
		},
	},
})
/*
 * Export reducer for store configuration.
 */
export const watchlistReducer = watchlistSlice.reducer
/*
 * Export action creators to be dispatched. For use with the `useDispatch` hook.
 */
export const {
	WATCH_LIST,
	WATCH_LIST_PAGING,
	WATCH_LIST_PAGING_SUCCESS,
	WATCH_LIST_CLEAR,
	WATCH_LIST_SUCCESS,
	WATCH_LIST_ERROR,
} = watchlistSlice.actions

/*
 * Set up the redux-observable epic
 */
export const watchlistEpic = (action$) =>
	action$.pipe(ofType(WATCH_LIST.type, WATCH_LIST_PAGING.type), mergeMap(watchlistService(action$)))

export const watchlistService = (action$) => (action) => {
	switch (action.type) {
		case WATCH_LIST.type: {
			const watchListEndPoint = getEndpointsFromMenu(action.payload.category)
			return HTTP.GET_WITH_CANCEL(
				watchListEndPoint,
				getHeaders(),
				watchlistSuccess,
				watchlistError(action),
				true,
				action$.pipe(ofType(WATCH_LIST))
			)
		}
		case WATCH_LIST_PAGING.type: {
			const watchListEndPoint = getEndpointsFromMenu('mylist')
			const watchListUrlObj = new URL(watchListEndPoint)
			watchListUrlObj.searchParams.set('page', action.payload.page)
			return HTTP.GET_WITH_CANCEL(
				watchListUrlObj.toString(),
				getHeaders(),
				watchlistPagingSuccess,
				watchlistError(action),
				true,
				action$.pipe(ofType(WATCH_LIST))
			)
		}
	}
}

const watchlistSuccess = (response) => {
	return {
		type: WATCH_LIST_SUCCESS.type,
		payload: successResponse(response),
	}
}

const watchlistPagingSuccess = (response) => {
	return {
		type: WATCH_LIST_PAGING_SUCCESS.type,
		payload: successResponse(response),
	}
}

const watchlistError = (action) => (response) => {
	return of(
		handleUnauthorized(
			response,
			{
				type: WATCH_LIST_ERROR.type,
				payload: errorResponse(response, action),
			},
			action
		)
	)
}
