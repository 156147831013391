import { createSlice } from '@reduxjs/toolkit'
import { mergeMap, filter } from 'rxjs/operators'
import { of } from 'rxjs'
import { ofType } from 'redux-observable'

import { ENV } from '../../utils/environment'
import { errorResponse, HTTP } from '../../utils/httpHelper'
import { getHeaders } from '../../utils/authHelper'
import { deviceRegistered, deviceNotRegistered } from '../../utils/constants'
import { getDeviceType } from '../../utils/platform'
import { handleUnauthorized } from '../../utils/authHelper'
import { getDeviceName, getProductType } from '../../utils/platform'
import { LOGOUT } from '../auth/auth.slice'

import { DEVICE_ID } from '../../utils/constants'
import { secureLocalStorage } from '../../utils/secureStorage'

export const DEVICES_FEATURE_KEY = 'devices'

/*
 * Create our slice
 */
export const devicesSlice = createSlice({
	name: DEVICES_FEATURE_KEY,
	initialState: {},
	reducers: {
		GET_ALL_DEVICES: (state) => {
			delete state.error
			delete state.serverError
			state.loading = true
		},
		DEVICES_SUCCESS: (state, action) => {
			state.loading = false
			state.items = action.payload
		},
		REGISTER_DEVICE: (state) => {
			delete state.error
			delete state.serverError
			state.loading = true
		},
		REGISTER_DEVICE_SUCCESS: (state) => {
			state.loading = false
		},
		REMOVE_DEVICE: (state) => {
			delete state.error
			delete state.serverError
			state.loading = true
		},
		REMOVE_DEVICE_SUCCESS: (state) => {
			state.loading = false
		},
		DEVICES_ERROR: (state, action) => {
			state.error = action.payload.error
			state.serverError = action.payload.serverError
			state.loading = false
		},
	},
	extraReducers: (builder) => {
		builder.addCase(LOGOUT.type, () => {
			return {}
		})
	},
})

/*
 * Export reducer for store configuration.
 */
export const devicesReducer = devicesSlice.reducer

/*
 * Export actions
 */
export const {
	GET_ALL_DEVICES,
	REGISTER_DEVICE,
	REMOVE_DEVICE,
	DEVICES_SUCCESS,
	DEVICES_ERROR,
	REGISTER_DEVICE_SUCCESS,
	DELETE_DEVICE_SUCCESS,
} = devicesSlice.actions

/*
 * Set up the redux-observable epic
 */
export const devicesEpic = (action$) =>
	action$.pipe(
		ofType(GET_ALL_DEVICES.type, REGISTER_DEVICE.type, REMOVE_DEVICE.type),
		mergeMap(devicesService(action$))
	)

/*
 * Do API calls
 */
const devicesService = (action$) => (action) => {
	switch (action.type) {
		case GET_ALL_DEVICES.type: {
			return HTTP.GET_WITH_CANCEL(
				ENV.GET_ALL_DEVICES,
				getHeaders(),
				showAllDevices(action),
				deviceError(action),
				false,
				action$.pipe(ofType(DEVICES_SUCCESS))
			)
		}
		case REGISTER_DEVICE.type: {
			let URL =
				ENV.REGISTER_DEVICE +
				`${action.payload.item.deviceID};deviceName=${action.payload.item.value};deviceType=${getDeviceType()}`
			return HTTP.POST(URL, null, getHeaders(), registerSuccess(action), deviceError(action), false)
		}
		case REMOVE_DEVICE.type: {
			let URL = ENV.DELETE_DEVICE + action.payload.item.deviceID
			return HTTP.DELETE(URL, null, getHeaders(), deleteSuccess(action), deviceError(action), false)
		}
	}
}

/*
 * Dispatch actions based on API responses
 */
const showAllDevices = () => (response) => {
	let result = []
	let currentDeviceId = secureLocalStorage.getItem(DEVICE_ID)
	let deviceName = getDeviceName()
	let registeredDevId = {
		value: deviceName,
		deviceID: currentDeviceId,
		tag: '(This Device)',
		subText: deviceNotRegistered.value,
	}
	if (response && response.deviceRegistrations.length > 0) {
		response.deviceRegistrations.forEach((item) => {
			if (currentDeviceId === item.deviceId) {
				registeredDevId = {
					value: item.name,
					deviceID: currentDeviceId,
					isRegistered: item.status,
					tag: currentDeviceId ? '(This Device)' : '',
					subText: item.status === 'ACTIVE' ? deviceRegistered.value : deviceNotRegistered.value,
				}
			} else {
				result.push({
					value: item.name,
					deviceID: item.deviceId,
					isRegistered: item.status,
					tag: '',
					subText: item.status === 'ACTIVE' ? deviceRegistered.value : deviceNotRegistered.value,
				})
			}
		})
	}
	//The Explora Ultra does not count as a registered device, hiding the option
	if (getProductType() !== 'EXPLORA_ULTRA') {
		result.unshift(registeredDevId)
	}
	return { type: DEVICES_SUCCESS.type, payload: result, isLoading: false }
}

const registerSuccess = () => (response) => {
	return { type: REGISTER_DEVICE_SUCCESS.type }
}

const deleteSuccess = () => (response) => {
	return { type: DELETE_DEVICE_SUCCESS.type }
}

const deviceError = (action) => (response) => {
	return of(
		handleUnauthorized(
			response,
			{
				type: DEVICES_ERROR.type,
				payload: errorResponse(response, action),
			},
			action
		)
	)
}
