import { createSlice } from '@reduxjs/toolkit'
import { ofType } from 'redux-observable'
import { getHeaders, handleUnauthorized } from '../../utils/authHelper'
import { ENV } from '../../utils/environment'
import { errorResponse, HTTP } from '../../utils/httpHelper'
import { mergeMap } from 'rxjs/operators'
import { of } from 'rxjs'

export const TERMS_FEATURE_KEY = 'terms'

export const termsSlice = createSlice({
	name: TERMS_FEATURE_KEY,
	initialState: { status: localStorage.getItem('terms_status') },
	reducers: {
		GET_TERMS: (state) => {
			state.loading = true
		},
		SET_TERMS: (state) => {
			state.loading = true
		},
		TERMS_SUCCESS: (state, action) => {
			localStorage.setItem('terms_status', action.payload.status)
			state.status = action.payload.status
			state.loading = false
		},
		TERMS_ERROR: (state) => {
			state.loading = false
		},
	},
})

/*
 * Export reducer for store configuration.
 */
export const termsReducer = termsSlice.reducer

/*
 * Export actions
 */
export const { GET_TERMS, SET_TERMS, TERMS_SUCCESS, TERMS_ERROR } = termsSlice.actions

/*
 * Set up the redux-observable epic
 */
export const termsEpic = (action$) =>
	action$.pipe(ofType(GET_TERMS.type, SET_TERMS.type), mergeMap(termsService(action$)))

/*
 * Do API calls
 */
export const termsService = () => (action) => {
	switch (action.type) {
		case GET_TERMS.type: {
			return HTTP.GET(ENV.TERMS_AND_CONDITIONS, getHeaders(), termsSuccess, termsError(action), true)
		}
		case SET_TERMS.type: {
			return HTTP.POST(ENV.TERMS_AND_CONDITIONS, action.payload, getHeaders(), termsSuccess, termsError(action), true)
		}
	}
}

const termsSuccess = (response) => {
	return {
		type: TERMS_SUCCESS.type,
		payload: { status: response.status },
	}
}

const termsError = (action) => (response) => {
	return of(
		handleUnauthorized(
			response,
			{
				type: TERMS_ERROR.type,
				payload: errorResponse(response, action),
			},
			action
		)
	)
}
