import styles from './NavIcon.module.scss'
import PropTypes from 'prop-types'

import { ReactComponent as ChannelsIcon } from '../../../assets/svg-icons/channels.svg'
import { ReactComponent as HomeIcon } from '../../../assets/svg-icons/home.svg'
import { ReactComponent as KidsIcon } from '../../../assets/svg-icons/kids.svg'
import { ReactComponent as LiveTVIcon } from '../../../assets/svg-icons/livetv.svg'
import { ReactComponent as MoviesIcon } from '../../../assets/svg-icons/movies.svg'
import { ReactComponent as MyListIcon } from '../../../assets/svg-icons/mylist.svg'
import { ReactComponent as SearchIcon } from '../../../assets/svg-icons/search.svg'
import { ReactComponent as SettingsIcon } from '../../../assets/svg-icons/settings.svg'
import { ReactComponent as SportIcon } from '../../../assets/svg-icons/sport.svg'
import { ReactComponent as TVShowsIcon } from '../../../assets/svg-icons/tvshows.svg'

export function NavIcon({ image, className, iconName }) {
	if (image) {
		return <img src={image} className={className} />
	}

	switch (iconName) {
		case 'channelgroups':
			return <ChannelsIcon className={className} />
		case 'home':
			return <HomeIcon className={className} />
		case 'kids':
			return <KidsIcon className={className} />
		case 'livetv':
			return <LiveTVIcon className={className} />
		case 'movies':
			return <MoviesIcon className={className} />
		case 'mylist':
			return <MyListIcon className={className} />
		case 'search':
			return <SearchIcon className={className} />
		case 'settings':
			return <SettingsIcon className={className} />
		case 'sport':
			return <SportIcon className={className} />
		case 'tvshows':
			return <TVShowsIcon className={className} />
		default:
			return <></>
	}
}

NavIcon.defaultProps = {
	className: '',
	iconName: undefined,
	image: undefined,
}

NavIcon.propTypes = {
	className: PropTypes.string,
	iconName: PropTypes.node,
	image: PropTypes.string,
}

export default NavIcon
