export const transformAppsResponse = (response, location) => {
    let list = response.items;

    list.forEach((res, index, list) => {
        list[index] = {
            id: res?.id || "",
            heroImage: res?.images.billboard ? res?.images.billboard.XLARGE : "",
            logo: res?.images.logos.THUMB || "",
            title: res?.title,
            trackingInfo: res?.meta.trackingInfo,
            appName: res?.launchInfo.appName || "",
            url: res?.launchInfo.url || "",
            appType: res?.launchInfo.appType || "",
            appLocation: location
        }
    });
    
    return list;
}