import { createSlice } from '@reduxjs/toolkit'
import { ofType } from 'redux-observable'
import { mergeMap } from 'rxjs/operators'
import { of } from 'rxjs'
import { getHeaders, handleUnauthorized } from '../../utils/authHelper'
import { ENV } from '../../utils/environment'
import { HTTP, errorResponse, successResponse } from '../../utils/httpHelper'
import { getCardPosterImageURL } from '../../utils/transformHelper'
import { getWatchlistDetails } from '../billboard/billboardTransformHelper'

export const SHOWPAGE_FEATURE_KEY = 'showpage'

export const showpageSlice = createSlice({
	name: SHOWPAGE_FEATURE_KEY,
	initialState: { billboardVisible: true },
	reducers: {
		CLEAR_SHOWPAGE: (state) => {
			state.editorials = null
			state.watchButton = null
			state.watchlistDetails = null
		},
		EDITORIALS: (state, action) => {
			delete state.error
			state.editorials = null
		},
		EDITORIALS_SUCCESS: (state, action) => {
			state.editorials = action.payload
			state.editorials?.map((item) => {
				item?.editorialItems?.map(
					(editorialItem) => (editorialItem.poster_image = getCardPosterImageURL(editorialItem))
				)
			})
			state.date = new Date().getTime()
		},
		EDITORIALS_ERROR: (state, action) => {
			state.editorials = null
		},
		UPDATE_WATCH_BUTTON: (state) => {
			state.watchButton = {
				loading: true,
			}
		},
		UPDATE_WATCH_BUTTON_SUCCESS: (state, action) => {
			state.watchButton = {
				...action.payload,
				loading: false,
			}
		},
		UPDATE_WATCH_BUTTON_ERROR: (state, action) => {
			state.watchButton = { resume_state: 'watch' }
			state.watchButton.loading = false
		},
		CLEAR_WATCH_BUTTON: (state) => {
			state.watchButton = null
		},
		TOGGLE_WATCHLIST: (state, action) => {
			state.watchlistDetails = getWatchlistDetails(action.payload.data, true)
			state.watchlistDetails.loading = true
		},
		TOGGLE_WATCHLIST_SUCCESS: (state, action) => {
			const watchlistDetails = getWatchlistDetails(action.payload.data, false)

			state.watchlistDetails = {
				...watchlistDetails,
				showToast: true,
				toastMessage: watchlistDetails.title,
				loading: false,
			}
		},
		TOGGLE_WATCHLIST_ERROR: (state, action) => {
			state.watchlistDetails = getWatchlistDetails(action.payload.data, false)
			state.watchlistDetails.loading = false
		},
		GET_WATCH_LIST_INFO: (state, action) => {
			state.loading = false
			state.watchlistDetails = null
		},
		GET_WATCH_LIST_INFO_SUCCESS: (state, action) => {
			state.watchlistDetails = getWatchlistDetails(action.payload.data, false)
		},
		GET_WATCH_LIST_INFO_ERROR: (state, action) => {
			state.loading = false
		},
		HIDE_WATCHLIST_TOAST: (state, action) => {
			if (state.watchlistDetails?.showToast) {
				state.watchlistDetails.showToast = false
			}
		},
	},
})
/*
 * Export reducer for store configuration.
 */
export const showpageReducer = showpageSlice.reducer

export const {
	CLEAR_SHOWPAGE,
	EDITORIALS,
	EDITORIALS_SUCCESS,
	EDITORIALS_ERROR,
	UPDATE_WATCH_BUTTON,
	UPDATE_WATCH_BUTTON_SUCCESS,
	UPDATE_WATCH_BUTTON_ERROR,
	CLEAR_WATCH_BUTTON,
	TOGGLE_WATCHLIST,
	TOGGLE_WATCHLIST_SUCCESS,
	TOGGLE_WATCHLIST_ERROR,
	GET_WATCH_LIST_INFO,
	GET_WATCH_LIST_INFO_SUCCESS,
	GET_WATCH_LIST_INFO_ERROR,
	HIDE_WATCHLIST_TOAST,
} = showpageSlice.actions

/*
 * Set up the redux-observable epic
 */
export const showpageEpic = (action$) =>
	action$.pipe(
		ofType(EDITORIALS.type, UPDATE_WATCH_BUTTON.type, TOGGLE_WATCHLIST.type, GET_WATCH_LIST_INFO.type),
		mergeMap(showpageService(action$))
	)

const showpageService = (action$) => (action) => {
	switch (action.type) {
		case EDITORIALS.type: {
			return HTTP.GET_WITH_CANCEL(
				ENV.BASE_URL + ENV.GET_TRY_THIS.replace('{VIDEO_ID}', action.payload.videoId),
				getHeaders(),
				editorialsSuccess,
				editorialsError(action),
				false,
				action$.pipe(ofType(EDITORIALS.type))
			)
		}
		case UPDATE_WATCH_BUTTON.type: {
			return HTTP.GET(
				ENV.UPDATE_WATCHBUTTON_API.replace('{type}', action.payload.type).replace('{id}', action.payload.id),
				getHeaders(),
				updateWatchButtonSuccess,
				updateWatchButtonError(action),
				true
			)
		}

		case TOGGLE_WATCHLIST.type: {
			if (action.payload.operation === 'ADD') {
				return HTTP.POST(
					action.payload.url,
					action.payload.data,
					getHeaders(),
					toggleWatchListSuccess,
					toggleWatchListError(action)
				)
			} else if (action.payload.operation === 'REMOVE') {
				return HTTP.DELETE(
					action.payload.url,
					action.payload.data,
					getHeaders(),
					toggleWatchListSuccess,
					toggleWatchListError(action)
				)
			}
			break
		}
		case GET_WATCH_LIST_INFO.type: {
			return HTTP.GET_WITH_CANCEL(
				action.payload.moreInfoEndpoint,
				getHeaders(),
				getWatchlistSuccess,
				getWatchlistError(action),
				true,
				action$.pipe(ofType(GET_WATCH_LIST_INFO))
			)
		}
	}
}

const editorialsSuccess = (response) => {
	let editorialRows = response?.items?.filter((row) => row?.editorialItems?.length)
	if (editorialRows?.length) {
		return { type: EDITORIALS_SUCCESS.type, payload: editorialRows }
	} else {
		return of({
			type: EDITORIALS_ERROR.type,
		})
	}
}

const editorialsError = (action) => (response) => {
	return of(
		handleUnauthorized(
			response,
			{
				type: EDITORIALS_ERROR.type,
			},
			action
		)
	)
}

const updateWatchButtonSuccess = (response) => {
	return {
		type: UPDATE_WATCH_BUTTON_SUCCESS.type,
		payload: { ...response },
	}
}

const updateWatchButtonError = (action) => (response) => {
	return of(
		handleUnauthorized(
			response,
			{
				type: UPDATE_WATCH_BUTTON_ERROR.type,
				payload: { ...response },
			},
			action
		)
	)
}

const toggleWatchListSuccess = (response) => {
	return {
		type: TOGGLE_WATCHLIST_SUCCESS.type,
		payload: { data: { ...(response.response || response) } },
	}
}

const toggleWatchListError = (action) => (response) => {
	return of(
		handleUnauthorized(
			response,
			{
				type: TOGGLE_WATCHLIST_ERROR.type,
				payload: { data: { ...action.payload.data } },
			},
			action
		)
	)
}

const getWatchlistSuccess = (response) => {
	return {
		type: GET_WATCH_LIST_INFO_SUCCESS.type,
		payload: successResponse(response?.preferences),
	}
}

const getWatchlistError = (action) => (response) => {
	return of(
		handleUnauthorized(
			response,
			{
				type: GET_WATCH_LIST_INFO_ERROR.type,
				payload: errorResponse(response),
			},
			action
		)
	)
}
