import { ACTIVE_PROFILE_ID, SESSION_ID, USER_ID, SUBSCRIPTION_PACKAGE, DEVICE_ID } from './constants'
import { secureLocalStorage, secureSessionStorage } from './secureStorage'
import { StorageKey } from './storageKey'

export function fetchFromLocalStorage(itemName) {
	return retrieveFromStorage(secureLocalStorage, itemName)
}

export function retriveFromSessionStorage(itemName) {
	return retrieveFromStorage(secureSessionStorage, itemName)
}

function retrieveFromStorage(storageObj, itemName) {
	const item = storageObj.getItem(itemName)
	if (item === false) return false
	if (!item || item === 'undefined' || item === 'null') return null
	return item
}

export function saveToLocalStorage(key, val) {
	secureLocalStorage.setItem(key, val)
}

export const getDeviceID = () => fetchFromLocalStorage(DEVICE_ID)
export const getSessionID = () => fetchFromLocalStorage(SESSION_ID)
export const getUserID = () => fetchFromLocalStorage(USER_ID)
export const getUserPackage = () => fetchFromLocalStorage(SUBSCRIPTION_PACKAGE)

export function getProfileId() {
	return retriveFromSessionStorage(ACTIVE_PROFILE_ID) || fetchFromLocalStorage(ACTIVE_PROFILE_ID)
}

export function getEndpointsFromMenu(pageType) {
	window.storeFsn = { getEndpointsFromMenu, fetchFromLocalStorage, retriveFromSessionStorage }
	try {
		let getMenuData =
			JSON.parse(secureSessionStorage.getItem(StorageKey.SK_NAV_DATA)) ||
			JSON.parse(secureLocalStorage.getItem(StorageKey.SK_NAV_DATA))
		let endpoint =
			getMenuData && getMenuData.data && getMenuData.data.filter((item) => item.id === pageType).pop().url.listing.url
		return endpoint
	} catch (e) {
		console.log(e)
	}
}
