// External dependency imports
import { HashRouter } from 'react-router-dom'
import { configureStore, createListenerMiddleware, combineReducers } from '@reduxjs/toolkit'
import { createEpicMiddleware, combineEpics } from 'redux-observable'
import { Provider } from 'react-redux'
import * as ReactDOM from 'react-dom/client'
import reportWebVitals from './setupWebVitals'
// Internal dependency imports
import App from './app/app'

import { ReportMiddleware } from '@dstv-web-leanback/dstv-frontend-middleware'

import {
	AD_PLAYER_FEATURE_KEY,
	adPlayerEpic,
	adPlayerReducer,
	APP_LIST_KEY,
	appListEpic,
	applisting,
	AUTH_FEATURE_KEY,
	authEpic,
	authReducer,
	AUTOPLAY_FEATURE_KEY,
	autoplayEpic,
	autoplayReducer,
	AVATARS_FEATURE_KEY,
	avatarsEpic,
	avatarsReducer,
	BILLBOARD_FEATURE_KEY,
	billboardEpic,
	billboardReducer,
	BOOKMARKS_FEATURE_KEY,
	bookmarksEpic,
	bookmarksReducer,
	CATCHUP_FEATURE_KEY,
	catchupEpic,
	catchupReducer,
	CDN_AUTH_FEATURE_KEY,
	cdnAuthEpic,
	cdnAuthReducer,
	CHANNELS_FEATURE_KEY,
	channelsEpic,
	channelsReducer,
	CONFIG_FEATURE_KEY,
	configEpic,
	configReducer,
	DEVICES_FEATURE_KEY,
	devicesEpic,
	devicesReducer,
	ENTITLEMENT_FEATURE_KEY,
	entitlementEpic,
	entitlementReducer,
	HOME_FEATURE_KEY,
	homeEpic,
	homeReducer,
	KEYBOARD_FEATURE_KEY,
	keyboardReducer,
	LOGOUT,
	MENU_FEATURE_KEY,
	menuEpic,
	menuReducer,
	NAV_FEATURE_KEY,
	navReducer,
	ONBOARDING_FEATURE_KEY,
	onboardingReducer,
	PLAYER_FEATURE_KEY,
	playerEpic,
	playerReducer,
	PROFILE_FEATURE_KEY,
	profileEpic,
	profileReducer,
	PROXIMITY_FEATURE_KEY,
	proximityEpic,
	proximityReducer,
	SEARCH_FEATURE_KEY,
	searchEpic,
	searchReducer,
	SECTIONS_FEATURE_KEY,
	sectionsReducer,
	segmentAnalytics,
	SESSION_FEATURE_KEY,
	sessionEpic,
	sessionReducer,
	SETTINGS_FEATURE_KEY,
	settingsReducer,
	SHOWPAGE_FEATURE_KEY,
	showpageEpic,
	showpageReducer,
	termsEpic,
	TERMS_FEATURE_KEY,
	termsReducer,
	USER_FEATURE_KEY,
	userEpic,
	userReducer,
	WATCH_LIST_FEATURE_KEY,
	watchlistEpic,
	watchlistReducer,
} from '@dstv-web-leanback/dstv-frontend-services'

const listenerInstance = createListenerMiddleware({
	extra: { worker: null, serviceWoker: null },
	onError: (error, errorInfo) => {
		console.error(error, errorInfo)
		reportError(error)
	},
})

const epicMiddleware = createEpicMiddleware()
const middlwareConfig = {
	thunk: true,
	immutableCheck: false,
	serializableCheck: false,
}

const appReducer = combineReducers({
	[TERMS_FEATURE_KEY]: termsReducer,
	[NAV_FEATURE_KEY]: navReducer,
	[AD_PLAYER_FEATURE_KEY]: adPlayerReducer,
	[WATCH_LIST_FEATURE_KEY]: watchlistReducer,
	[SHOWPAGE_FEATURE_KEY]: showpageReducer,
	[AUTH_FEATURE_KEY]: authReducer,
	[AUTOPLAY_FEATURE_KEY]: autoplayReducer,
	[AVATARS_FEATURE_KEY]: avatarsReducer,
	[BILLBOARD_FEATURE_KEY]: billboardReducer,
	[CATCHUP_FEATURE_KEY]: catchupReducer,
	[CDN_AUTH_FEATURE_KEY]: cdnAuthReducer,
	[CHANNELS_FEATURE_KEY]: channelsReducer,
	[CONFIG_FEATURE_KEY]: configReducer,
	[DEVICES_FEATURE_KEY]: devicesReducer,
	[ENTITLEMENT_FEATURE_KEY]: entitlementReducer,
	[HOME_FEATURE_KEY]: homeReducer,
	[PROXIMITY_FEATURE_KEY]: proximityReducer,
	[KEYBOARD_FEATURE_KEY]: keyboardReducer,
	[MENU_FEATURE_KEY]: menuReducer,
	[PLAYER_FEATURE_KEY]: playerReducer,
	[PROFILE_FEATURE_KEY]: profileReducer,
	[SEARCH_FEATURE_KEY]: searchReducer,
	[SECTIONS_FEATURE_KEY]: sectionsReducer,
	[SESSION_FEATURE_KEY]: sessionReducer,
	[SETTINGS_FEATURE_KEY]: settingsReducer,
	[USER_FEATURE_KEY]: userReducer,
	[APP_LIST_KEY]: applisting,
	[BOOKMARKS_FEATURE_KEY]: bookmarksReducer,
	[ONBOARDING_FEATURE_KEY]: onboardingReducer,
})

const rootReducer = (state, action) => {
	if (action.type === LOGOUT.type) {
		return appReducer(undefined, action)
	}

	return appReducer(state, action)
}

const store = configureStore({
	reducer: rootReducer,
	// Additional middleware can be passed to this array

	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware(middlwareConfig)
			.prepend(listenerInstance.middleware)
			.concat([epicMiddleware, ReportMiddleware]),
	devTools: process.env.NODE_ENV !== 'production',
})

const rootEpic = combineEpics(
	appListEpic,
	authEpic,
	autoplayEpic,
	avatarsEpic,
	billboardEpic,
	catchupEpic,
	cdnAuthEpic,
	channelsEpic,
	configEpic,
	devicesEpic,
	entitlementEpic,
	homeEpic,
	proximityEpic,
	menuEpic,
	playerEpic,
	adPlayerEpic,
	profileEpic,
	searchEpic,
	sessionEpic,
	showpageEpic,
	termsEpic,
	userEpic,
	watchlistEpic,
	bookmarksEpic
)

epicMiddleware.run(rootEpic)
window.isSmartTv = true
const root = ReactDOM.createRoot(document.getElementById('root'))

const Root = () => {
	return (
		<Provider store={store}>
			<HashRouter>
				<App />
			</HashRouter>
		</Provider>
	)
}

root.render(<Root />)

const sendWebVitalSegment = ({ name, ...entry }) => {
	let ident = ['webmetrics', name].join('->')
	segmentAnalytics.track(ident, { name, ...entry })
}

reportWebVitals(sendWebVitalSegment)
