import lottie from 'lottie-web'
import { useEffect, useRef } from 'react'
import spinnerData from '../../../assets/images/smarttv/loading_spinner.json'

export const LottieAnimation = ({ animationData, loop, onComplete, style = { height: '10vw' } }) => {
	const animationContainer = useRef(null)

	useEffect(() => {
		const anim = lottie.loadAnimation({
			container: animationContainer.current,
			renderer: 'svg',
			loop: loop || false,
			autoplay: true,
			animationData: animationData || spinnerData,
		})
		anim.onComplete = function () {
			onComplete && onComplete()
		}
		return () => {
			anim.destroy()
		}
	}, [animationData, loop, onComplete])

	return <div ref={animationContainer} style={style} />
}

export default LottieAnimation
