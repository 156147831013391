class helper {
	static adPlayer = null
	static session = null
	static adPlayerListener = null
	static details = null
	static firstPlay = null
	static platform = null //'Desktop' null will use Pulse SDK auto platform detection
	static isLive
	static cleanUp = false
	static exit = false
	static adSession = null
	static isAdPlaying = false
	static canSkip = false
	static hasSkipped = false
	static PulseAdErrors = null
}
