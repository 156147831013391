import { useEffect, useState } from 'react'

import { HOUR_WIDTH } from '@dstv-web-leanback/dstv-frontend-services'

import styles from './Timeline.module.scss'

export function Timeline({ currentTime, gridStart }) {
	const [timeLabels, setTimeLabels] = useState(
		Array.from({ length: 24 }, (v, i) => i.toString().padStart(2, '0') + ':00')
	)

	const [offsetLeft, setOffsetLeft] = useState(
		(currentTime.hours() + currentTime.minutes() / 60) * HOUR_WIDTH - gridStart.hours() * HOUR_WIDTH
	)
	const [closestHour, setClosestHour] = useState(null)

	useEffect(() => {
		setOffsetLeft((prevOffsetLeft) => prevOffsetLeft + 11)

		if (currentTime) {
			if (currentTime.minutes() >= 50) {
				setClosestHour(currentTime.hour() + 1)
			} else if (currentTime.minutes() < 5) {
				setClosestHour(currentTime.hour())
			} else {
				setClosestHour(null)
			}
		}
	}, [currentTime])

	return (
		<div className={styles.timeline} style={{ width: `${(24 - gridStart.hours()) * HOUR_WIDTH}px` }}>
			{offsetLeft && (
				<div style={{ left: offsetLeft }} className={styles.current_time}>
					{currentTime.format('HH:mm')}
				</div>
			)}
			{timeLabels.map((time, index) => {
				if (index >= gridStart.hours()) {
					return (
						<div
							key={time}
							style={{ visibility: closestHour === index ? 'hidden' : 'visible', width: `${HOUR_WIDTH}px` }}
						>
							{time}
						</div>
					)
				}
			})}
		</div>
	)
}

export default Timeline
