import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { FocusContext, useFocusable, setFocus } from '@connected-video-web/norigin-spatial-navigation'

import { Recommendation } from '@dstv-web-leanback/dstv-frontend-components'

import styles from './ShowpageRecommendations.module.scss'

export function ShowpageRecommendations({ rows }) {
	const { focusKey, hasFocusedChild, ref } = useFocusable({
		trackChildren: true,
		onBackPress: () => {
			setFocus('BUTTON_0')
		},
	})

	const [pageScroll, setPageScroll] = useState(0)

	const onRecommendationFocus = (focusedRef) => {
		setPageScroll(focusedRef.offsetTop)
	}
	return (
		<FocusContext.Provider value={focusKey}>
			<div ref={ref} className={`${styles.showpage_recommendations_wrapper} ${hasFocusedChild && styles.active}`}>
				<div className={styles.showpage_recommendations} style={{ transform: `translate3d(0, -${pageScroll}px, 0)` }}>
					{rows?.map((row, index) => {
						return <Recommendation key={index} row={row} index={index} onRecommendationFocus={onRecommendationFocus} />
					})}
				</div>
			</div>
		</FocusContext.Provider>
	)
}
export default ShowpageRecommendations
