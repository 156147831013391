import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useFocusable } from '@connected-video-web/norigin-spatial-navigation'

import { NavIcon } from '@dstv-web-leanback/dstv-frontend-components'

import styles from './NavItem.module.scss'

export function NavItem({ focusKey, id, itemEnterPress, itemFocus, subtitle, title, icon, ...rest }) {
	const { ref, focused, onFocus } = useFocusable({
		onEnterPress: itemEnterPress && itemEnterPress,
		onFocus: itemFocus && itemFocus,
		focusKey: `${focusKey}_${id.toUpperCase().replace(/ /g, '_')}`,
	})

	const { hidden } = useSelector((state) => state.menu)
	const location = useLocation()

	const active = useMemo(() => {
		return location.pathname.replace('/', '') === id
	}, [id, location.pathname])

	return (
		<div
			ref={ref}
			className={`${styles['nav-item']} ${focused ? styles.focus : ''} ${hidden ? styles.hidden : ''} ${
				active ? styles.active : ''
			}`}
			onFocus={onFocus}
		>
			{/* <img src={icon || menuIcons[id]} className={styles.icon} /> */}
			<NavIcon image={icon} className={styles.icon} iconName={id} />
			<span className={styles.title}>{title}</span>
		</div>
	)
}

NavItem.defaultProps = {
	active: false,
	title: 'item',
	subtitle: '',
}

NavItem.propTypes = {
	active: PropTypes.bool,
	title: PropTypes.string.isRequired,
	subtitle: PropTypes.string,
}

export default NavItem
