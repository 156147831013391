import { useCallback, useEffect, useRef } from 'react'
import SHA256 from 'crypto-js/sha256'
import { secureLocalStorage } from '@dstv-web-leanback/dstv-frontend-services'

export const usePermutive = (userId) => {
	const permutive = useRef()

	useEffect(() => {
		try {
			if (userId && userId !== null) {
				var lastSyncDate = new Date(localStorage.getItem('multichoice-permutive-invidi-lastsync') || 0)
				var now = new Date()
				var lastSyncDay = lastSyncDate.getUTCDay()
				var today = now.getUTCDay()

				if (
					typeof window.permutive !== 'undefined' &&
					typeof window.permutive.ready === 'function' &&
					!window.permutive.loaded
				) {
					window.permutive.ready(() => {
						window.permutive.identify([
							{
								id: SHA256(userId).toString(),
								tag: 'internal_base36',
							},
						])

						window.permutive.loaded = true
					}, 'initialised')

					if (lastSyncDate.getTime() < now.getTime() - 1000 * 60 * 60 * 24 || today != lastSyncDay) {
						setTimeout(() => {
							var segments = localStorage.getItem('_invidi')

							if (segments) {
								var sList = JSON.parse(segments)

								fetch(
									'https://za-mc.videoplaza.tv/proxy/pixel/v2?permutive=' +
										sList
											.map(function (e) {
												return e + '%3Dy'
											})
											.join('%2C') +
										'&pid=' +
										userId
								)
							}
						}, 10000)

						localStorage.setItem('multichoice-permutive-invidi-lastsync', now.toISOString())
					}
				}
			}
		} catch (e) {
			console.error(e)
		}
	}, [userId])

	const handleVideoPlayback = useCallback((videoData, trackingPlayId) => {
		window.permutive.track('VideoPlay', {
			play_id: trackingPlayId,
			video: {
				duration: videoData.durationInSeconds || 0,
				name: `${videoData.displayTitle || videoData.title} S${
					videoData.seasonNumber || videoData.season_number || 0
				}E${videoData.seasonEpisode || videoData.episode_number || 0}`,
				video_id: videoData.id,
				description:
					videoData.synopsis || videoData.shortSynopsis || videoData.event_synopsis || videoData.description || '',
			},
			enriched_data: {
				genre: videoData.genres?.[0] || videoData.card_genres?.[0] || videoData.genre,
				category: videoData.categorisations?.[0]?.subCategoryName || '',
				type: videoData.categorisations?.[0]?.categoryName || '',
				channel: videoData.channels?.[0]?.channelNumber || videoData.number || '',
				package: secureLocalStorage.getItem('userPackage'),
			},
		})
	}, [])

	return { handleVideoPlayback }
}
