import { isString } from './isHelper'
import { getBy, getIn, setIn, has } from './objHelper'
import { ENV } from '../utils/environment'

//This is the card info URL for PB
export const getCardInfoURL = (response, type) => {
	//checking for type and passing it, as homepage has events and card info
	let data =
		response &&
		response.links &&
		response.links.filter((item) => {
			return item.rel[0] === 'item' && item.rel[1] === type
		})
	return data.length && data[0].href.replace('virtual://dstv_now_production', 'https://ssl.dstv.com/api/dstv_now')
}

//This the channel details for the VOD items on home.. Like the logo etc
export const getCardChannelInfoURL = (response) => {
	let data =
		response &&
		response.card_channel &&
		response.card_channel.filter((item) => {
			return item.rel[0] === 'channel'
		})
	return data.length && data[0].href.replace('virtual://dstv_now_production', 'https://ssl.dstv.com/api/dstv_now')
}

export const getEventThumbURL = (response) => {
	let data =
		response &&
		response.event_images &&
		response.event_images.filter((item) => {
			return item.rel[0] === 'thumbnailUri' && item.rel[1] === 'THUMB'
		})
	return data.length && data[0].href
}

export const getCardLogoURL = (response) => {
	if (response.images && Array.isArray(response.images)) {
		let data = response.images.filter((item) => {
			return item.rel[0] === 'logo'
		})
		return data[0]?.href
	} else {
		let data =
			response &&
			response.card_images &&
			response.card_images.filter((item) => {
				return item.rel[0] === 'NowWeb' && item.rel[1] === 'MEDIUM'
			})
		return data.length && data[0].href
	}
}

export const getCardPosterImageURL = (response) => {
	if (response.images && Array.isArray(response.images)) {
		let data = response?.images?.filter((item) => {
			return item.rel[0] === 'poster'
		})
		return data[0]?.href
	} else if (response.card_images) {
		// new PB response
		let data =
			response &&
			response.card_images &&
			response?.card_images?.filter((item) => {
				return item.rel[0] === 'poster' && item.rel[1] === 'MEDIUM'
			})
		return data.length && data[0].href
	} else if (response?.video) {
		//movies or tvshows
		return response?.video?.images?.poster?.MEDIUM
	} else if (response?.program?.images) {
		//tv shows
		return response?.program?.images?.poster?.MEDIUM
	} else if (response?.editorialItemType && !response?.image) {
		return response?.images?.poster?.MEDIUM
	} else if (response?.image?.LARGE) {
		return response?.image?.LARGE
	}
	return null
}

export const transformMenuItems = (menuItems) => {
	let result = []

	menuItems?.forEach((item) => {
		result.push({
			id: item.id || item.name,
			title: item.name || '',
			url: item?.endpoint,
		})
	})
	return result
}

export const getChannelAPIEndpoint = (response) => {
	let url = null
	try {
		url = response?.links?.filter((link) => link?.rel?.[0] === 'channel')[0]?.href

		if (!url) {
			url = response?.channel?.filter((link) => link?.rel?.[0] === 'channel')[0]?.href
		}
	} catch {
		// catch error
	}
	return urlFix(url)
}
const videoIdDeep = getBy('program.seasons.0.videos.0.id'),
	videoId = getBy('video.id'),
	itemId = getBy('id')
export const getVideoId = (item) => {
	return videoIdDeep(item, videoId(item, itemId(item)))
	//return item?.program?.seasons?.[0]?.videos?.[0]?.id || item?.video?.id || item?.id
}

export const urlFix = (url) =>
	isString(url)
		? url.replace('virtual://dstv_now_production', `${ENV.BASE_API_END_POINT || 'https://ssl.dstv.com/api'}/dstv_now`)
		: url

export const getRelObject = (input) =>
	input.reduce((acc, { rel, href }) => {
		rel = rel.join('.')
		setIn(acc, rel, urlFix(href))
		return acc
	}, {})

export const getRelObjects = (
	obj,
	keys = ['images', 'links', 'list', 'channel', 'card_images', 'event_images', 'card_channel']
) => keys.filter((k) => k in obj).reduce((acc, k) => ({ ...acc, [k]: getRelObject(obj[k]) }), {})

export const getRel = (item) => {
	if (item) {
		let relLookUp = getRelObjects({ ...item })
		item.rel = relLookUp
	}
	return item
}
export const mapRel = (
	item,
	copyInto = true,
	accessorMap = { posterImage: 'images.poster.MEDIUM', heroImage: 'images.hero.LARGE' }
) => {
	if (item) {
		if (!has(item, 'rel')) {
			item.rel = getRel(item)
		}
		let relLookUp = item.rel
		let accessors = Object.entries(accessorMap).reduce(
			(acc, [key, value]) => {
				let orig = getIn(acc, key, null)
				let newValue = getIn(relLookUp, value, orig)
				if (newValue || !copyInto) {
					acc[key] = newValue
				}
				return acc
			},
			copyInto ? { ...item } : {}
		)
		return accessors
	}
	return item
}
