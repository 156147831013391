import styles from './Card16x9.module.scss'
import { ProgressBar } from '@dstv-web-leanback/dstv-frontend-components'
import { useFocusable, getCurrentFocusKey } from '@connected-video-web/norigin-spatial-navigation'
import { getCardLogoURL } from '@dstv-web-leanback/dstv-frontend-services'

import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { getProgressBarPercent, formatTimeToHoursAndMinutes } from '@dstv-web-leanback/dstv-frontend-components'
import { NAV_PUSH, PLAY, useNavigation } from '@dstv-web-leanback/dstv-frontend-services'

import playicon from '../../../assets/images/play.png'
import logoFallback from '../../../assets/images/dstv_logo_fallback.png'
import thumbFallback from '../../../assets/images/thumb_fallback.png'

export function Card16x9({ index, item, rowId, rowTitle, itemFocus, type, ...rest }) {
	const { ref, focused } = useFocusable({
		onFocus: () => {
			if (ref) itemFocus(ref, item, index)

			if (rest.getCardDetails && !item.fetched_info) {
				getCardDetails(item, rowId)
			}
		},
		onEnterPress: () => {
			navigateToPlayer()
		},
		focusKey: `CARD_${rowId}${index}`,
	})
	const { getCardDetails } = rest
	let dispatch = useDispatch()
	const { location, navigate } = useNavigation()

	useEffect(() => {
		if (index === 0 && item?.tracking_info?.row === 0) {
			itemFocus(ref, item, index)
			getCardDetails(item, rowId)
		}

		let io = new IntersectionObserver((entries) => {
			if (entries[0].isIntersecting) {
				if (rest.getCardDetails && !item.fetched_info && !item.poster_image) {
					getCardDetails(item, rowId)
				}
			}
		})

		if (ref.current) {
			io.observe(ref.current)
		}

		return () => {
			if (ref.current) {
				io.unobserve(ref.current)
			}
		}
	}, [])

	const navigateToPlayer = () => {
		let streamUrl = item?.links?.filter((link) => link?.rel?.[0] === 'stream')[0]?.href
		dispatch(
			PLAY({
				url: streamUrl || '',
				details: { ...item, channelTag: item.tag, rowTitle: rowTitle },
				type: 'live',
			})
		)
		dispatch(NAV_PUSH({ key: getCurrentFocusKey(), location: location.pathname }))
		navigate('/play', { state: { from: location.pathname.replace('/', '') } })
	}

	return (
		<div ref={ref} className={`${styles.container} ${focused ? styles.active : ''}`}>
			<div className={styles.card}>
				<header>
					{item.images && (
						<span>
							<img
								className={styles.logo}
								src={getCardLogoURL(item)}
								alt="Channel logo"
								onError={(e) => {
									e.target.src = logoFallback
								}}
							/>
						</span>
					)}
					<span>{item?.number}</span>
				</header>
				<span>
					<img className={styles['playButton']} src={playicon} />
					<img
						className={styles.image}
						src={item.poster_image || thumbFallback}
						alt="Event thumbnail"
						onError={(e) => {
							e.target.src = thumbFallback
						}}
					/>
				</span>
				<div className={styles.overlay}></div>
				<div className={styles.meta}>
					{item.start_date_time && item.end_date_time && (
						<p className={styles.time}>
							{formatTimeToHoursAndMinutes(item?.start_date_time)} - {formatTimeToHoursAndMinutes(item?.end_date_time)}
						</p>
					)}
					<p className={styles.title}>{item?.title}</p>
					{item.start_date_time && (
						<div className={styles.progress_wrapper}>
							<ProgressBar progress={getProgressBarPercent(item)} />
						</div>
					)}
				</div>
			</div>
		</div>
	)
}
export default Card16x9
