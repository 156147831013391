import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
	CLEAR_NEW_PROFILE_DETAILS,
	DELETE_PROFILE,
	DELETE_PROFILE_FLAGS,
	GET_PROFILES_LIST,
	PROFILE_FEATURE_KEY,
	REMOVE_PROFILE_CRUD,
	useNavigation,
} from '@dstv-web-leanback/dstv-frontend-services'
import { useNavigate, useParams } from 'react-router-dom'
import { FocusContext, useFocusable, setFocus } from '@connected-video-web/norigin-spatial-navigation'

import {
	DialogButtonGroup,
	Loader,
	ProfileImage,
	getNewProfileDetails,
} from '@dstv-web-leanback/dstv-frontend-components'

import styles from './ProfileDelete.module.scss'
import dstvLogo from '../../../../assets/images/dstv_logo_fallback.png'

export function ProfileDelete(props) {
	const { ref, focusKey } = useFocusable({
		onBackPress: () => {
			navigate(`/profiles`)
		},
	})

	const { id } = useParams()
	const { navigateBack } = useNavigation()
	const navigate = useNavigate()
	const dispatch = useDispatch()

	const profiles = useSelector((state) => state[PROFILE_FEATURE_KEY].data?.items)

	const profileCrud = useSelector((state) => state[PROFILE_FEATURE_KEY].profileCrud)

	const newProfile = useSelector((state) => state[PROFILE_FEATURE_KEY].newProfile)

	const loading = useSelector((state) => state[PROFILE_FEATURE_KEY].loading)

	const selectedProfile = profiles?.find((item) => item.id === id)

	useEffect(() => {
		setFocus('BUTTON_CANCEL')
	}, [setFocus])

	useEffect(() => {
		if (profileCrud) {
			dispatch(CLEAR_NEW_PROFILE_DETAILS())
			dispatch(DELETE_PROFILE_FLAGS())
			dispatch(GET_PROFILES_LIST())
			navigate('/profiles')
		}
	}, [profileCrud, navigate, dispatch])

	let { alias, avatarUri } = getNewProfileDetails(selectedProfile, newProfile)

	const actionButtons = [
		{
			focusKeyParam: 'BUTTON_CANCEL',
			title: 'Cancel',
			onItemFocus: () => {
				return
			},
			action: (payload) => {
				navigateBack()
			},
		},
		{
			focusKeyParam: 'BUTTON_DELETE',
			title: 'Delete Profile',
			onItemFocus: () => {
				return
			},
			action: (payload) => {
				dispatch(REMOVE_PROFILE_CRUD())
				dispatch(
					DELETE_PROFILE({
						profileId: id,
					})
				)
			},
		},
	]

	return (
		<FocusContext.Provider value={focusKey}>
			<div ref={ref} className={styles.container}>
				{loading ? <Loader /> : null}
				<img className={styles.dstv_logo} alt="DStv logo" src={dstvLogo} />
				<div className={styles.title}>Are you sure you want to delete your profile?</div>
				<ProfileImage
					imageType="profile"
					alias={alias}
					avatarUri={avatarUri}
					onSelectItem={() => {
						navigate('/profiles/edit/' + id)
					}}
					onItemFocused={() => {
						return
					}}
				/>
				<div className={styles['name__delete']}>{alias}</div>
				<div className={styles['description']}>
					This profile's history and activity will be deleted forever, and you won't be able to access it again.
				</div>
				<DialogButtonGroup actionButtons={actionButtons} />
			</div>
		</FocusContext.Provider>
	)
}
export default ProfileDelete
