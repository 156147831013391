import { HOUR_WIDTH } from '@dstv-web-leanback/dstv-frontend-services'
import ChannelEvent from '../../core/ChannelEvent/ChannelEvent'
import styles from './ChannelEventsRow.module.scss'

export function ChannelEventsRow({ channel, currentTime, gridStart, ...rest }) {
	return (
		<div className={styles.channel_events_row} style={{ width: `${24 * HOUR_WIDTH}px` }}>
			{channel.events?.map((event) => {
				if (event.end?.isAfter(gridStart)) {
					return <ChannelEvent key={event.id} currentTime={currentTime} event={event} gridStart={gridStart} {...rest} />
				}
			})}
		</div>
	)
}
export default ChannelEventsRow
