// External Dependencies
import { useEffect, useRef, useState, useImperativeHandle, forwardRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Loader } from '@dstv-web-leanback/dstv-frontend-components'
import {
	useNavigation,
	secureLocalStorage,
	IS_AD_PLAYING,
	GET_LIVE_AD_REQUEST,
	AD_SESSION,
	RELOAD_STREAM,
	RESET_TRY_THIS,
	IS_PLAYER_READY,
} from '@dstv-web-leanback/dstv-frontend-services'
import { FocusContext, useFocusable, setFocus } from '@connected-video-web/norigin-spatial-navigation'

// Internal Dependencies
import helper from './helper'
import './skin-1.6.3.js'
import './skin64.css'
import styles from './PulsePlayer.module.scss'

export const PulsePlayer = forwardRef(({ content, onEnded }, ref) => {
	// Internal State Management
	const adPlayerRef = useRef(null)
	const dispatch = useDispatch()

	const config = useSelector((state) => state.config.data)
	const userState = useSelector((state) => state.user)
	const playerState = useSelector((state) => state.player)
	const bookmarkState = useSelector((state) => state.bookmarks.bookmarks)
	const adPlayerState = useSelector((state) => state.adPlayer)

	const { progress } = useSelector((state) => state.billboard)
	const { liveAdRequest, isContentPlayerReady } = useSelector((state) => state.adPlayer)

	// Local State
	const [loading, setLoading] = useState(false)
	const [pulseAdState, setPulseAdState] = useState(null)

	const { navigateBack } = useNavigation()
	const {
		focusKey,
		ref: focusRef,
		focusSelf,
	} = useFocusable({
		trackChildren: true,
		isFocusBoundary: true,
		onBackPress: () => {
			exitPlayerSession('back')
		},
		onEnterPress: () => {
			if (helper.canSkip) {
				helper.adPlayer.skipButtonClicked()
				helper.canSkip = false
				setFocusableVal('PLAY_PAUSE')
			}
		},
	})

	//EXIT AdPlayer if in session
	const exitPlayerSession = (val) => {
		setPulseAdState({ type: 'EXIT_PULSE_PLAYER', data: null, event: null })
		dispatch(IS_PLAYER_READY(false))
		dispatch(IS_AD_PLAYING(false))
		helper.exit = true
		try {
			if (!helper.sessionEnded && helper.adPlayer) {
				helper.adPlayer.stopSession()
				helper.adPlayer.destroy()
				setFocusableVal('PLAY_PAUSE')
				dispatch(IS_AD_PLAYING(false))
			}
		} catch (error) {
			console.warn(error)
		}

		if (val === 'back') {
			navigateBack()
		}
	}

	//Focus Events
	const setAdPlayerFocus = () => {
		if (!helper.cleanUp) focusSelf()
	}

	const setFocusableVal = (val) => {
		setFocus(val)
	}
	//Focus Events --END

	//Playback Type
	const handleVodAdPlayback = (adRequest) => {
		setPulseAdState({ type: 'PLAYING_VOD_ASSET', data: adRequest?.tt, event: null })
		helper.isLive = false
		let episodeListBookmark = false
		let progressBookmark = progress

		//VOD asset Bookmark check
		if (bookmarkState) {
			const modifiedGenref = playerState?.details?.genref.replace('IS20_', '')
			const containsModifiedGenref = bookmarkState.some((item) => item.genref === modifiedGenref)
			episodeListBookmark = containsModifiedGenref
			progressBookmark = 0
		}

		if (isContentPlayerReady && adRequest?.tt?.length > 0) {
			const hasBookmark = progressBookmark > 1 ? true : episodeListBookmark
			let dai_pre_roll = config?.dai_vod_prerolls?.flagValue === 'true' ? true : false
			let dai_mid_roll = config?.dai_vod_midrolls?.flagValue === 'true' ? true : false
			let dai_post_roll = config?.dai_vod_postrolls?.flagValue === 'true' ? true : false
			transformAdRequestObj(adRequest, hasBookmark, dai_pre_roll, dai_mid_roll, dai_post_roll)

			setPulseAdState({ type: 'DOES_ASSET_HAVE_BOOKMARK', data: hasBookmark, event: null })
		}
	}

	const handleLiveAdPlayback = (channelTag) => {
		setPulseAdState({ type: 'PLAYING_LIVE_ASSET', data: null, event: null })
		helper.isLive = true
		if (channelTag) {
			if (liveAdRequest && isContentPlayerReady) {
				transformAdRequestObj(liveAdRequest, false, true, true, false)
			} else {
				dispatch(GET_LIVE_AD_REQUEST({ channelTag, num_of_events: 1 }))
			}
		}
	}
	//Playback Type -- END

	//Ad Player Management
	const transformAdRequestObj = (adRequest, hasBookmark, dai_pre_roll, dai_mid_roll, dai_post_roll) => {
		const insertionPointFilterArray = adRequest.tt
			.map((item) => {
				if (item === 'p' && dai_pre_roll && !hasBookmark) {
					return 'onBeforeContent'
				} else if (item === 'm' && dai_mid_roll) {
					return 'playbackPosition'
				} else if (item === 'po' && dai_post_roll) {
					return 'onContentEnd'
				}
				return undefined
			})
			.filter((item) => item)

		const isObjectEmpty = (objectName) => {
			return Object.keys(objectName).length === 0
		}

		const PA = `PA:${userState.userPackage}`
		const AO =
			config.ssai_enabled?.payload && config.ssai_enabled?.payload?.scheduling === 'Linear'
				? 'AO:' + (config.ssai_enabled?.payload?.status || '')
				: ''

		const pulseTestingTags = []
		if (!isObjectEmpty(config.ssai_enabled.payload)) {
			if (playerState.type === 'vod') {
				pulseTestingTags.push(PA)
			}
			if (playerState.type === 'vod' || playerState.type === 'live') {
				pulseTestingTags.push(AO)
			}
		}

		setPulseAdState({ type: 'AD_PLAY_INPUTS', data: insertionPointFilterArray, event: null })

		helper.details = {
			host: adRequest.pulse_host,
			contentMetadata: {
				accountCustomParameters: {
					...adRequest.acp,
					package: userState.userPackage,
				},
				category: adRequest.s,
				tags: [...adRequest.t, ...pulseTestingTags],
			},
			requestSettings: {
				enableGdpr: false,
				height: window.innerHeight,
				width: window.innerWidth,
				linearPlaybackPositions: adRequest.bp,
				insertionPointFilter: insertionPointFilterArray,
				seekMode: OO.Pulse.SeekMode.PLAY_LAST,
			},
		}

		initializeAdPlayer()
	}

	const initializeAdPlayer = () => {
		OO.Pulse.setPulseHost(helper.details.host, helper.platform, secureLocalStorage.getItem('userID'))

		helper.adPlayerListener = {
			startContentPlayback: function () {
				setFocusableVal('PLAY_PAUSE')
				dispatch(IS_AD_PLAYING(false))
				content.play()
			},
			pauseContentPlayback: function () {
				content.pause()
			},
			illegalOperationOccurred: function (message) {
				console.warn('Illegal operation: ', message)
			},
			sessionEnded: function () {
				setPulseAdState({ type: 'SESSION_ENDED', data: null, event: null })
				onEnded()
			},
			openClickThrough: function (url) {
				//do not delete
			},
		}

		helper.session = OO.Pulse.createSession(helper.details.contentMetadata, helper.details.requestSettings)

		helper.adPlayer = OO.Pulse.createAdPlayer({
			adContainerElement: adPlayerRef.current,
		})

		helper.adPlayer.addEventListener(OO.Pulse.AdPlayer.Events.SESSION_STARTED, function (event, data) {
			setPulseAdState({ type: 'SESSION_STARTED', data: data, event: event })
			dispatch(AD_SESSION('start'))
		})

		helper.adPlayer.addEventListener(OO.Pulse.AdPlayer.Events.LINEAR_AD_STARTING, function (event, data) {
			setPulseAdState({ type: 'LINEAR_AD_STARTING', data: data, event: event })
			const startPlayback = data.defer()
			setLoading(true)

			setTimeout(() => {
				startPlayback()
			}, 500)
		})

		helper.adPlayer.addEventListener(OO.Pulse.AdPlayer.Events.LINEAR_AD_PAUSED, function (event, data) {
			setPulseAdState({ type: 'LINEAR_AD_PAUSED', data: data, event: event })
			helper.adPlayer.play()
		})

		helper.adPlayer.addEventListener(OO.Pulse.AdPlayer.Events.LINEAR_AD_STARTED, function (event, data) {
			setPulseAdState({ type: 'LINEAR_AD_STARTED', data: data, event: event })
			helper.isAdPlaying = true
			setLoading(false)
			dispatch(IS_AD_PLAYING(true))
			content.pause()
			if (!helper.isLive) helper.adPlayer.contentPaused()
		})

		helper.adPlayer.addEventListener(OO.Pulse.AdPlayer.Events.LINEAR_AD_FINISHED, function (event, data) {
			setPulseAdState({ type: 'LINEAR_AD_FINISHED', data: data, event: event })
			helper.isAdPlaying = false
		})

		helper.adPlayer.addEventListener(OO.Pulse.AdPlayer.Events.LINEAR_AD_PROGRESS, function (event, data) {
			setAdPlayerFocus()
			if (helper.exit) {
				try {
					helper.adPlayer.stopSession()
					helper.exit = false
				} catch (e) {
					console.warn(e)
				}
			}
		})

		helper.adPlayer.addEventListener(OO.Pulse.AdPlayer.Events.LINEAR_AD_PLAYING, function (event, data) {
			setPulseAdState({ type: 'LINEAR_AD_PLAYING', data: data, event: event })
			setLoading(false)
			dispatch(IS_AD_PLAYING(true))
			setAdPlayerFocus()
		})

		helper.adPlayer.addEventListener(OO.Pulse.AdPlayer.Events.LINEAR_AD_SKIPPED, function (event, data) {
			setPulseAdState({ type: 'LINEAR_AD_SKIPPED', data: data, event: event })
			helper.hasSkipped = true
		})

		helper.adPlayer.addEventListener(OO.Pulse.AdPlayer.Events.SHOW_SKIP_BUTTON, function (event, data) {
			setPulseAdState({ type: 'LINEAR_AD_SKIPPED', data: data, event: event })
			helper.canSkip = true
			setAdPlayerFocus()
		})

		helper.adPlayer.addEventListener(OO.Pulse.AdPlayer.Events.AD_BREAK_FINISHING, function (event, data) {
			setPulseAdState({ type: 'AD_BREAK_FINISHING', data: data, event: event })
			helper.contentFinishing = true
		})

		helper.adPlayer.addEventListener(OO.Pulse.AdPlayer.Events.AD_BREAK_FINISHED, function (event, data) {
			setPulseAdState({ type: 'AD_BREAK_FINISHED', data: data, event: event })
			setFocusableVal('PLAY_PAUSE')
			dispatch(IS_AD_PLAYING(false))
			setLoading(true)
			var adBreak = data.adBreak
			if (adBreak.getBreakPosition() !== OO.Pulse.AdBreakPosition.PREROLL) return
			setPulseAdState({ type: 'PREROLL_END', data: adBreak.getBreakPosition(), event: event })
			setTimeout(() => {
				setLoading(false)
				helper.adPlayer.contentStarted()
				setPulseAdState({ type: 'RELOAD_STREAM', data: null, event: null })
				dispatch(RELOAD_STREAM())
			}, 500)
		})

		helper.adPlayer.addEventListener(OO.Pulse.AdPlayer.Events.AD_SESSION_FINISHED, function (event, data) {
			setPulseAdState({ type: 'AD_SESSION_FINISHED', data: data, event: event })
		})

		helper.adPlayer.addEventListener(OO.Pulse.AdPlayer.Events.AD_BREAK_EMPTY, function (event, data) {
			setPulseAdState({ type: 'AD_BREAK_EMPTY', data: data, event: event })
			setFocusableVal('PLAY_PAUSE')
		})

		helper.adPlayer.addEventListener(OO.Pulse.AdPlayer.Events.LINEAR_AD_ERROR, function (event, data) {
			setPulseAdState({ type: 'LINEAR_AD_ERROR', data: data, event: event })
			helper.PulseAdErrors = {
				error: 'LINEAR_AD_ERROR',
				data: data,
			}
			setLoading(false)
			setFocusableVal('PLAY_PAUSE')
			dispatch(IS_AD_PLAYING(false))
		})

		helper.adPlayer.setVolume(content.volume)

		setUpContentPlayerListeners()

		//for debuging pulse API
		//OO.Pulse.debug = true
	}
	//Ad Player Management -- END

	//Content Player Events
	const setUpContentPlayerListeners = () => {
		content.addEventListener('play', onPlay)
		content.addEventListener('pause', onPause)
		content.addEventListener('timeupdate', onTimeUpdate)
		content.addEventListener('ended', onEnd)
	}

	const onEnd = () => {
		setPulseAdState({ type: 'CONTENT_END', data: null, event: null })
		content.removeEventListener('timeupdate', onTimeUpdate)
		helper.adPlayer.contentStarted()
		helper.adPlayer.contentFinished()
	}

	const onPlay = (e) => {
		if (helper.firstPlay) {
			setPulseAdState({ type: 'CONTENT_FIRST_PLAY', data: null, event: null })
			helper.firstPlay = false
			content.pause()
			helper.adPlayer.startSession(helper.session, helper.adPlayerListener)
		} else {
			setPulseAdState({ type: 'CONTENT_PLAY', data: null, event: null })
		}
	}

	const onPause = (e) => {
		if (!helper.isLive) helper.adPlayer.contentPaused()
	}

	const onTimeUpdate = (e) => {
		if (!helper.isLive && !content.paused && playerState.isContentPlayerReady) {
			//helper.adPlayer.contentPositionChanged(Math.round(e.target.currentTime))
		}
	}
	//Content Player Events -- END

	//Flagr value checks
	const canPlayVodAds = () => {
		if (
			config?.dai_vod_prerolls?.flagValue === 'true' ||
			config?.dai_vod_midrolls?.flagValue === 'true' ||
			config?.dai_vod_postrolls?.flagValue === 'true'
		) {
			setPulseAdState({ type: 'CAN_PLAY_VOD_ASSET', data: true, event: null })
			return true
		} else {
			setPulseAdState({ type: 'CAN_PLAY_VOD_ASSET', data: true, event: null })
			return false
		}
	}

	const canPlayLinearAds = () => {
		if (config?.dai_linear_prerolls?.flagValue === 'true') {
			setPulseAdState({ type: 'CAN_PLAY_LIVE_ASSET', data: true, event: null })
			return true
		} else {
			setPulseAdState({ type: 'CAN_PLAY_LIVE_ASSET', data: false, event: null })
			return false
		}
	}
	//Flagr value checks

	//Clean up
	const removeAllAdPlayerListeners = () => {
		helper.adPlayer.removeEventListener(OO.Pulse.AdPlayer.Events.SESSION_STARTED)
		helper.adPlayer.removeEventListener(OO.Pulse.AdPlayer.Events.LINEAR_AD_STARTING)
		helper.adPlayer.removeEventListener(OO.Pulse.AdPlayer.Events.LINEAR_AD_PAUSED)
		helper.adPlayer.removeEventListener(OO.Pulse.AdPlayer.Events.LINEAR_AD_STARTED)
		helper.adPlayer.removeEventListener(OO.Pulse.AdPlayer.Events.LINEAR_AD_FINISHED)
		helper.adPlayer.removeEventListener(OO.Pulse.AdPlayer.Events.LINEAR_AD_PROGRESS)
		helper.adPlayer.removeEventListener(OO.Pulse.AdPlayer.Events.LINEAR_AD_PLAYING)
		helper.adPlayer.removeEventListener(OO.Pulse.AdPlayer.Events.SHOW_SKIP_BUTTON)
		helper.adPlayer.removeEventListener(OO.Pulse.AdPlayer.Events.AD_BREAK_FINISHING)
		helper.adPlayer.removeEventListener(OO.Pulse.AdPlayer.Events.AD_BREAK_FINISHED)
		helper.adPlayer.removeEventListener(OO.Pulse.AdPlayer.Events.AD_SESSION_FINISHED)
		helper.adPlayer.removeEventListener(OO.Pulse.AdPlayer.Events.AD_BREAK_EMPTY)
		helper.adPlayer.removeEventListener(OO.Pulse.AdPlayer.Events.LINEAR_AD_ERROR)
	}

	const removeContentPlayerListeners = () => {
		content.removeEventListener('play', onPlay)
		content.removeEventListener('pause', onPause)
		content.removeEventListener('timeupdate', onTimeUpdate)
		content.removeEventListener('ended', onEnd)
	}

	const cleanUp = () => {
		helper.cleanUp = true
		if (content && helper.adPlayer) removeAllAdPlayerListeners()
		if (content && helper.adPlayer) removeContentPlayerListeners()
	}
	//Clean up -- END

	//Content Player errors
	const error = () => {
		if (helper.adPlayer) {
			cleanUp()
			exitPlayerSession()
		}
	}

	const resetPulseValues = () => {
		helper.PulseAdErrors = null
		helper.cleanUp = false
		helper.exit = false
		helper.firstPlay = true
		helper.isAdPlaying = false
		helper.canSkip = false
		helper.hasSkipped = false
	}

	useEffect(() => {
		if (!OO.Pulse || !content) {
			return
		}
		const { adRequest, channelTag } = playerState.details || {}

		setPulseAdState({
			type: 'playerState.type',
			data: playerState.type,
			event: null,
		})

		if (playerState.type === 'vod') {
			canPlayVodAds() && handleVodAdPlayback(adRequest)
		} else {
			canPlayLinearAds() && handleLiveAdPlayback(channelTag)
		}
	}, [isContentPlayerReady, liveAdRequest, content, playerState.details])

	useEffect(() => {
		if (!content) return

		setPulseAdState({
			type: 'canPlayVodAds - canPlayLinearAds',
			data: { vod: canPlayVodAds(), live: canPlayLinearAds() },
			event: null,
		})
		resetPulseValues()
		return () => {
			if (!canPlayVodAds() || !canPlayLinearAds()) {
				cleanUp()
				exitPlayerSession()
			}
		}
	}, [content])

	//If Ad is playing Do NOT show try this
	useEffect(() => {
		if (playerState.tryThis?.length > 0 && (helper.isAdPlaying || helper.hasSkipped)) {
			if (helper.hasSkipped) helper.hasSkipped = false
			dispatch(RESET_TRY_THIS())
		}
	}, [playerState.tryThis])

	//Check adSession State
	useEffect(() => {
		helper.adSession = adPlayerState.adSession
	}, [adPlayerState.adSession])

	//For class Debugging
	useEffect(() => {
		//console.log(pulseAdState)
	}, [pulseAdState])

	useImperativeHandle(ref, () => ({
		error,
	}))

	return (
		<FocusContext.Provider value={focusKey}>
			<div ref={focusRef} className={styles.ad_player}>
				<div ref={adPlayerRef} className={styles.ad_container} id="adContainer"></div>
				{loading ? <Loader /> : null}
			</div>
		</FocusContext.Provider>
	)
})

export default PulsePlayer
