import { createSlice } from '@reduxjs/toolkit'
import { mergeMap } from 'rxjs/operators'
import { of } from 'rxjs'
import { ofType } from 'redux-observable'

import { APP_VERSION, ENV, platformId } from '../../utils/environment'
import { errorResponse, HTTP } from '../../utils/httpHelper'
import { getAuthorizationHeaders, handleUnauthorized } from '../../utils/authHelper'
import { secureLocalStorage } from '../../utils/secureStorage'

export const CONFIG_FEATURE_KEY = 'config'

/*
 * Create our slice
 */
export const configSlice = createSlice({
	name: CONFIG_FEATURE_KEY,
	initialState: {},
	reducers: {
		GET_REMOTE_CONFIGS: (state, action) => {
			delete state.error
			delete state.serverError
			state.loading = true
		},
		GET_REMOTE_CONFIGS_SUCCESS: (state, action) => {
			state.loading = false
			state.data = action.payload
		},
		GET_REMOTE_CONFIGS_ERROR: (state, action) => {
			state.error = action.payload.error
			state.serverError = action.payload.serverError
			state.loading = false
		},
	},
})

/*
 * Export reducer for store configuration.
 */
export const configReducer = configSlice.reducer

/*
 * Export actions
 */
export const { GET_REMOTE_CONFIGS, GET_REMOTE_CONFIGS_SUCCESS, GET_REMOTE_CONFIGS_ERROR } = configSlice.actions

/*
 * Set up the redux-observable epic
 */
export const configEpic = (action$) => action$.pipe(ofType(GET_REMOTE_CONFIGS.type), mergeMap(configService(action$)))

/*
 * Do API calls
 */
const configService = (action$) => (action) => {
	switch (action.type) {
		case GET_REMOTE_CONFIGS.type: {
			let data = {
				entityID: secureLocalStorage.getItem('userID'),
				entityContext: {
					platformId: platformId,
					base36Id: secureLocalStorage.getItem('userID'),
					appVersion: APP_VERSION,
				},
			}

			return HTTP.POST(
				ENV.REMOTE_CONFIGS,
				data,
				getAuthorizationHeaders(),
				getRemoteConfigSuccess(action),
				getRemoteConfigError(action),
				true
			)
		}
	}
}

/*
 * Dispatch actions based on API responses
 */
const getRemoteConfigSuccess = () => (response) => {
	let configs = (response?.response || response)?.configs
	let data = {}

	configs.forEach((config) => {
		data[config.flagKey] = { ...config }
	})

	return {
		type: GET_REMOTE_CONFIGS_SUCCESS.type,
		payload: data,
	}
}

const getRemoteConfigError = (action) => (response) => {
	return of(
		handleUnauthorized(
			response,
			{
				type: GET_REMOTE_CONFIGS_ERROR.type,
				payload: errorResponse(response, action),
			},
			action
		)
	)
}
