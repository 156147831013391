import { useEffect } from 'react'
import { useFocusable, FocusContext } from '@connected-video-web/norigin-spatial-navigation'
import { Grid } from '@dstv-web-leanback/dstv-frontend-components'

import styles from './CatchupContainer.module.scss'

export function CatchupContainer({
	cards,
	setGridFocused,
	changeGridFocus,
	paginateCatchupGrid,
	focusKeyProp,
	cardsLoaded,
}) {
	const { focusKey, hasFocusedChild, ref } = useFocusable({
		focusKey: focusKeyProp,
		saveLastFocusedChild: false,
		trackChildren: true,
		focusable: cardsLoaded,
	})

	useEffect(() => {
		setGridFocused(hasFocusedChild)
	}, [hasFocusedChild])

	return (
		<FocusContext.Provider value={focusKey}>
			<div ref={ref} className={`${styles.catchup_container} ${hasFocusedChild ? styles.active : ''}`}>
				{cards && <Grid cards={cards} itemFocus={changeGridFocus} paginateCatchupGrid={paginateCatchupGrid} />}
			</div>
		</FocusContext.Provider>
	)
}
export default CatchupContainer
