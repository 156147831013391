import { createSlice } from '@reduxjs/toolkit'
import { of } from 'rxjs'
import { mergeMap } from 'rxjs/operators'
import { ofType } from 'redux-observable'

import { handleUnauthorized, getHeaders } from '../../utils/authHelper'
import { ENV } from '../../utils/environment'
import { errorResponse, HTTP } from '../../utils/httpHelper'

export const AD_PLAYER_FEATURE_KEY = 'adPlayer'
/*
 * Create our slice
 */
export const adPlayerSlice = createSlice({
	name: AD_PLAYER_FEATURE_KEY,
	initialState: {
		liveAdRequest: undefined,
		isContentPlayerReady: false,
		playState: false,
		playing: false,
		exitAdSession: false,
		startPlaying: false,
	},
	reducers: {
		START_PLAYING: (state, action) => {
			state.startPlaying = action.payload
		},
		IS_AD_PLAYING: (state, action) => {
			state.playing = action.payload
		},
		GET_LIVE_AD_REQUEST: (state) => {
			state.loading = true
			delete state.error
			delete state.serverError
		},
		LIVE_AD_REQUEST_SUCCESS: (state, action) => {
			state.loading = false
			state.liveAdRequest = action.payload
		},
		LIVE_AD_REQUEST_ERROR: (state, action) => {
			state.loading = false
			state.error = action.payload.error
			state.serverError = action.payload.serverError
		},
		IS_PLAYER_READY: (state, action) => {
			state.isContentPlayerReady = action.payload
		},
		AD_SESSION: (state, action) => {
			state.adSession = action.payload
		},
		RESET_SESSION: (state, action) => {
			state.resetSession = action.payload
		},
		EXIT_AD_SESSION: (state, action) => {
			state.exitAdSession = action.payload
		},
		RELOAD_STREAM: (state) => {
			state.reloadStream = !state.reloadStream
		},
	},
})

/*
 * Export reducer for store configuration.
 */
export const adPlayerReducer = adPlayerSlice.reducer

/*
 * Export actions
 */
export const {
	GET_LIVE_AD_REQUEST,
	LIVE_AD_REQUEST_SUCCESS,
	LIVE_AD_REQUEST_ERROR,
	IS_PLAYER_READY,
	EXIT_AD_SESSION,
	IS_AD_PLAYING,
	START_PLAYING,
	AD_SESSION,
	RELOAD_STREAM,
	RESET_SESSION,
} = adPlayerSlice.actions

/*
 * Set up the redux-observable epic
 */
export const adPlayerEpic = (action$) =>
	action$.pipe(ofType(GET_LIVE_AD_REQUEST.type), mergeMap(adPlayerService(action$)))

/*
 * Do API calls
 */
export const adPlayerService = (action$) => (action) => {
	switch (action.type) {
		case GET_LIVE_AD_REQUEST.type: {
			return HTTP.GET(
				ENV.GET_AD_REQUEST_API_URL.replace('{channel_tag}', action.payload.channelTag).replace(
					'{num_of_events}',
					action.payload.num_of_events
				),
				getHeaders(),
				getLiveAdRequestSuccess(action),
				getLiveAdRequestError(action),
				true
			)
		}
	}
}

const getLiveAdRequestSuccess = (action) => (response) => {
	return {
		type: LIVE_AD_REQUEST_SUCCESS.type,
		payload: response.items?.[0]?.ad_request,
	}
}

const getLiveAdRequestError = (action) => (response) => {
	return of(
		handleUnauthorized(
			response,
			{
				type: LIVE_AD_REQUEST_ERROR.type,
				payload: errorResponse(response, action),
			},
			action
		)
	)
}
