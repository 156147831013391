import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useFocusable, FocusContext, setFocus } from '@connected-video-web/norigin-spatial-navigation'

import {
	DialogFull,
	SearchInput,
	CardPortrait,
	Keyboard,
	LottieAnimation,
} from '@dstv-web-leanback/dstv-frontend-components'
import { CLEAR_KEYBOARD, CLEAR_SEARCH } from '@dstv-web-leanback/dstv-frontend-services'

import { focusMainNavigation } from '../../utils/helper'
import styles from './Search.module.scss'

export function Search() {
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const searchState = useSelector((state) => state.search)
	const keyboardState = useSelector((state) => state.keyboard)

	const [itemScroll, setItemScroll] = useState(0)

	// Functions
	const changeItemFocus = (focusedRef) => {
		let gridStyles = getComputedStyle(ref.current)
		setItemScroll(focusedRef.current.offsetTop - Number.parseInt(gridStyles.paddingTop))
	}

	const { focusKey, ref } = useFocusable({
		onBackPress: () => {
			focusMainNavigation()
			dispatch(CLEAR_KEYBOARD())
			dispatch(CLEAR_SEARCH())
		},
	})

	useEffect(() => {
		setItemScroll(0)
	}, [keyboardState])

	if (searchState.error) {
		return (
			<DialogFull
				error={searchState.error}
				serverError={searchState.serverError}
				retryAction={() => {
					navigate(0)
				}}
			/>
		)
	}

	return (
		<FocusContext.Provider value={focusKey}>
			<div>
				<SearchInput />
				<div className={styles['search_results']}>
					<div className={styles['TvSearch_search_grid']}>
						<div className={styles['keyboard_grid']}>
							<Keyboard actions={['space', 'clear', 'symbols']} />
						</div>

						<div className={styles.grid_wrapper}>
							<div className={styles.grid} ref={ref} style={{ transform: `translate3d(0, -${itemScroll}px, 0)` }}>
								{searchState?.items?.length ? (
									<div className={styles.results_grid}>
										{searchState.items.map((item, index) => {
											return (
												<span className={styles.grid_item} key={item.id}>
													<CardPortrait
														index={index}
														className={styles.search_card}
														item={item}
														itemFocus={changeItemFocus}
														key={index}
													/>
												</span>
											)
										})}
									</div>
								) : null}

								{searchState?.items?.length === 0 ? (
									<div className={styles.text_item}>
										<h2>Sorry, we couldn't find any results for: "{keyboardState.value}" </h2>
									</div>
								) : null}
							</div>
							{searchState.isLoading ? (
								<div className={styles.loader_item}>
									<LottieAnimation loop={true} style={{ height: '5vw' }} />
								</div>
							) : null}
						</div>
					</div>
				</div>
			</div>
		</FocusContext.Provider>
	)
}
export default Search
