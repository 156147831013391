import { entitlementErrors } from './constants'

export function createAppWideError() {
	return {
		mainHeading: "We're Sorry",
		subHeading: 'DStv is temporarily unavailable as something unexpected went wrong.',
		body: ' Please check again in a few minutes time.',
		buttons: 'Reload App',
		showLogo: true,
	}
}

export function isForMissingRecord(error) {
	return error.statusCode === 404
}

export const smartTvErrorList = {
	defaultMessage: {
		mainHeading: 'Whoops',
		body: 'Looks like something went wrong. Please try again.',
		buttons: [
			{
				title: 'Try Again',
				action: 'retry',
			},
			{
				title: 'Back',
				action: 'back',
			},
		],
	},
	commonIrdetoMessage: {
		mainHeading: 'Whoops',
		body: 'Looks like something went wrong. This may be related to reaching your limit of one concurrent stream or due to a streaming error. If you are currently streaming on another device, please stop streaming and try again.',
		buttons: [
			{
				title: 'Try Again',
				action: 'retry',
			},
			{
				title: 'Back',
				action: 'back',
			},
		],
	},
	noContentTvMessage: {
		mainHeading: 'Whoops',
		body: 'An error occurred while trying to load content for',
		buttons: [
			{
				title: 'Try Again',
				action: 'retry',
			},
			{
				title: 'Back',
				action: 'back',
			},
		],
	},
	concurrency: {
		mainHeading: 'Streaming limit reached',
		body: [
			`You may have reached the limit of streams available on your account. To stream on this device, you'll need to end the stream already in use.`,
			`Just switched from another device? It may take a few minutes for stream to refresh.`,
		],
		buttons: [
			{
				title: 'Try Again',
				action: 'retry',
			},
			{
				title: 'Back',
				action: 'back',
			},
		],
		size: 'large',
	},
	proximity: {
		mainHeading: 'Proximity Error',
		body: 'Cannot stream on this device as another Leanback device is streaming in your home network and this device is outside your home network.',
		buttons: [
			{
				title: 'Try Again',
				action: 'retry',
			},
			{
				title: 'Back',
				action: 'back',
			},
		],
	},
	deviceRegisteredToAnotherUser: {
		body: 'This device is registered to a different user. Please remove this device on the other account.',
		buttons: [
			{
				title: 'Try Again',
				action: 'retry',
			},
			{
				title: 'Back',
				action: 'back',
			},
		],
	},
	maxDeviceRegistration: {
		mainHeading: 'We’re sorry',
		body: 'You have reached your device limit on your account. To watch DStv on this device, please remove a device from "Manage Devices" in settings.',
		buttons: [
			{
				title: 'Manage Devices',
				action: 'navigate',
				url: '/settings/devices',
			},
			{
				title: 'Back',
				action: 'back',
			},
		],
	},
	networkMessage: {
		mainHeading: 'No internet connection',
		body: 'Please check your network settings and try again or contact your Internet Service Provider (ISP) if the problem persists.',
		buttons: [
			{
				title: 'Try Again',
				action: 'retry',
			},
		],
		isNetworkError: true,
	},
	idleMessage: {
		mainHeading: '',
		subHeading: '',
		buttons: [{ title: 'Continue' }, { title: 'Back' }],
		isVisible: true,
	},
	disclaimerMessage: {
		mainHeading: '',
		subHeading: '',
		buttons: [{ title: 'Okay' }],
		isVisible: true,
	},
	pollingTimeOutMessage: {
		mainHeading: 'Your session is about to time out',
		subHeading: 'Do you want to continue?',
		buttons: [{ title: 'Continue' }, { title: 'Cancel' }],
		isVisible: true,
		retryAction: { type: 'START_POLLING_SUBSCRIPTION' },
	},
	CDNAT: {
		mainHeading: 'Something went wrong, we are unable to play video right now',
		subHeading: 'CDNAT 500',
		buttons: [{ title: 'retry' }],
		isVisible: true,
	},
	proximityPlaybackError: {
		mainHeading: '',
		subHeading: '',
		buttons: [{ title: 'Retry' }, { title: 'Back' }],
		isVisible: true,
	},
	profilesError: (reason, key) => {
		return {
			subHeading: reason,
			buttons: [{ title: 'Ok' }],
			key,
		}
	},
}

export const profileErrors = [
	{ key: 'NAME', reason: 'already exists, please enter a different name' },
	{ key: 'AVATAR', reason: 'Avatar is already linked to another profile' },
	{ key: 'PROFILE', reason: 'This profile has been deleted' },
	{ key: 'PROFILE', reason: 'You have reached maximum profiles allowed for single connect user' },
	{ key: 'PROFILE', reason: 'You have reached the maximum number of profiles allowed' },
	{ key: 'PROFILE', reason: 'Profile does not exist' },
]

export const checkProfilesError = (reason) => {
	let result = {}
	if (reason) {
		result = profileErrors.find((item) => reason.includes(item.reason) || item.reason.includes(reason))
	}
	return result
}

export const ErrorMessages = {
	getErrorMessageByStatusCode(httpStatusCode, error = {}, serviceName) {
		let profileError = checkProfilesError(error.reason) || {}
		if (profileError.key) {
			return smartTvErrorList.profilesError(error.reason, profileError.key)
		} else {
			if (error && error.errorCode && error.errorCode.toString() === '9') {
				return smartTvErrorList.maxDeviceRegistration
			}
			switch (httpStatusCode) {
				default: {
					return smartTvErrorList.defaultMessage
				}
			}
		}
	},

	getErrorMessageByName(errorName, episodeTitle) {
		switch (errorName) {
			case 'concurrency': {
				return smartTvErrorList.concurrency
			}
			case 'deviceLimit': {
				return smartTvErrorList.maxDeviceRegistration
			}
			case 'proximity': {
				return smartTvErrorList.proximity
			}
			case 'network': {
				return smartTvErrorList.networkMessage
			}
			case 'idlemessage': {
				const idleMessage = 'Are you still watching :' + ' ' + episodeTitle
				smartTvErrorList.idleMessage.subHeading = idleMessage
				return smartTvErrorList.idleMessage
			}
			case 'disclaimerMessage': {
				smartTvErrorList.disclaimerMessage.mainHeading = episodeTitle.title
				smartTvErrorList.disclaimerMessage.subHeading = episodeTitle.body
				return smartTvErrorList.disclaimerMessage
			}
			case 'pollingTimeOutMessage': {
				return smartTvErrorList.pollingTimeOutMessage
			}
			case 'CDNAT': {
				return smartTvErrorList.CDNAT
			}
			default: {
				return smartTvErrorList.defaultMessage
			}
		}
	},

	getErrorMessageByEntitlementResponse(errorResponse, status) {
		if (errorResponse?.title?.includes(entitlementErrors.maxDevice)) {
			return smartTvErrorList.maxDeviceRegistration
		} else if (status === 403 && errorResponse?.title && errorResponse.detail) {
			smartTvErrorList.proximityPlaybackError.mainHeading = errorResponse.title
			smartTvErrorList.proximityPlaybackError.subHeading = errorResponse.detail
			return smartTvErrorList.proximityPlaybackError
		}
		if (errorResponse && errorResponse.errorCode) {
			switch (errorResponse.errorCode.toString()) {
				case '2': {
					return smartTvErrorList.defaultMessage
				}
				case '7': {
					return smartTvErrorList.deviceRegisteredToAnotherUser
				}
				case '9': {
					return smartTvErrorList.maxDeviceRegistration
				}
				default: {
					return smartTvErrorList.defaultMessage
				}
			}
		}
		return smartTvErrorList.defaultMessage
	},
}
