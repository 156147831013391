import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useFocusable, FocusContext } from '@connected-video-web/norigin-spatial-navigation'
import {
	DISPLAY_NOTIFICATION_OVERLAY,
	NOTIFICATION_OVERLAY_BUTTON_CLICKED,
} from '@dstv-web-leanback/dstv-frontend-services'

import { Button, Countdown } from '@dstv-web-leanback/dstv-frontend-components'

import playIcon from '../../../assets/images/icons/icon-play.svg'

import styles from './AutoPlayNextPopup.module.scss'

export function AutoPlayNextPopup({ playNextEpisode, cancel, displayHeading, displayEpisode, countDownCounter }) {
	const dispatch = useDispatch()

	const { focusKey, focusSelf, ref } = useFocusable({
		isFocusBoundary: true,
		onBackPress: () => {
			cancel()
		},
	})

	const buttons = [
		{
			icon: playIcon,
			onSelect: () => {
				dispatch(
					NOTIFICATION_OVERLAY_BUTTON_CLICKED({
						overlay: 'Autoplay Next Episode',
						title: displayHeading,
						buttonText: 'Watch Now',
					})
				)
				playNextEpisode()
			},
			title: 'Watch Now',
		},
		{
			title: 'Watch Credits',
			onSelect: () => {
				dispatch(
					NOTIFICATION_OVERLAY_BUTTON_CLICKED({
						overlay: 'Autoplay Next Episode',
						title: displayHeading,
						buttonText: 'Watch Credits',
					})
				)
				cancel()
			},
		},
	]

	useEffect(() => {
		dispatch(DISPLAY_NOTIFICATION_OVERLAY({ overlay: 'Autoplay Next Episode', title: displayHeading }))
		focusSelf()
	}, [])

	return (
		<FocusContext.Provider value={focusKey}>
			<div ref={ref} className={styles.autoplay_popup}>
				<div className={styles.autoplay_header}>
					<p>Next Episode</p>
					<Countdown countDownTimer={Math.max(countDownCounter, 0)} />
				</div>
				<div className={styles.autoplay_body}>
					<h2>{displayHeading}</h2>
					<h3>{displayEpisode}</h3>
				</div>
				<div className={styles.buttons_wrapper}>
					{buttons.map((button, index) => (
						<Button id={index} key={index} {...button} />
					))}
				</div>
			</div>
		</FocusContext.Provider>
	)
}
export default AutoPlayNextPopup
