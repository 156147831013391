import { getConfigPayloadValue } from '@dstv-web-leanback/dstv-frontend-services'
import moment from 'moment'

export const LANGUAGE_CODES = {
	ab: 'Abkhazian',
	aa: 'Afar',
	af: 'Afrikaans',
	ak: 'Akan',
	sq: 'Albanian',
	am: 'Amharic',
	ar: 'Arabic',
	an: 'Aragonese',
	hy: 'Armenian',
	as: 'Assamese',
	av: 'Avaric',
	ae: 'Avestan',
	ay: 'Aymara',
	az: 'Azerbaijani',
	bm: 'Bambara',
	ba: 'Bashkir',
	eu: 'Basque',
	be: 'Belarusian',
	bn: 'Bengali',
	bh: 'Bihari',
	bi: 'Bislama',
	bs: 'Bosnian',
	br: 'Breton',
	bg: 'Bulgarian',
	my: 'Burmese',
	ca: 'Catalan',
	ch: 'Chamorro',
	ce: 'Chechen',
	ny: 'Chichewa',
	zh: 'Chinese',
	cv: 'Chuvash',
	kw: 'Cornish',
	co: 'Corsican',
	cr: 'Cree',
	hr: 'Croatian',
	cs: 'Czech',
	da: 'Danish',
	dv: 'Maldivian',
	nl: 'Dutch',
	dz: 'Dzongkha',
	en: 'English',
	eo: 'Esperanto',
	et: 'Estonian',
	ee: 'Ewe',
	fo: 'Faroese',
	fj: 'Fijian',
	fi: 'Finnish',
	fr: 'French',
	ff: 'Fula',
	gl: 'Galician',
	gd: 'Gaelic-Scottish',
	gv: 'Gaelic-Manx',
	ka: 'Georgian',
	de: 'German',
	el: 'Greek',
	kl: 'Greenlandic',
	gn: 'Guarani',
	gu: 'Gujarati',
	ht: 'Haitian-Creole',
	ha: 'Hausa',
	he: 'Hebrew',
	hz: 'Herero',
	hi: 'Hindi',
	ho: 'Hiri-Motu',
	hu: 'Hungarian',
	is: 'Icelandic',
	io: 'Ido',
	ig: 'Igbo',
	id: 'Indonesian',
	in: 'Indonesian',
	ia: 'Interlingua',
	ie: 'Interlingue',
	iu: 'Inuktitut',
	ik: 'Inupiak',
	ga: 'Irish',
	it: 'Italian',
	ja: 'Japanese',
	jv: 'Javanese',
	kn: 'Kannada',
	kr: 'Kanuri',
	ks: 'Kashmiri',
	kk: 'Kazakh',
	km: 'Khmer',
	ki: 'Kikuyu',
	rw: 'Kinyarwanda',
	rn: 'Kirundi',
	ky: 'Kyrgyz',
	kv: 'Komi',
	kg: 'Kongo',
	ko: 'Korean',
	ku: 'Kurdish',
	kj: 'Kwanyama',
	lo: 'Lao',
	la: 'Latin',
	lv: 'Latvian-Lettish',
	li: 'Limburgish-Limburger',
	ln: 'Lingala',
	lt: 'Lithuanian',
	lu: 'Luga-Katanga',
	lg: 'Luganda',
	lb: 'Luxembourgish',
	mk: 'Macedonian',
	mg: 'Malagasy',
	ms: 'Malay',
	ml: 'Malayalam',
	mt: 'Maltese',
	mi: 'Maori',
	mr: 'Marathi',
	mh: 'Marshallese',
	mo: 'Moldavian',
	mn: 'Mongolian',
	na: 'Nauru',
	nv: 'Navajo',
	ng: 'Ndonga',
	nd: 'Northern-Ndebele',
	ne: 'Nepali',
	no: 'Norwegian',
	nb: 'Norwegian-bokmål',
	nn: 'Norwegian-nynorsk',
	ii: 'Nuosu',
	oc: 'Occitan',
	oj: 'Ojibwe',
	cu: 'Old-Bulgarian',
	or: 'Oriya',
	om: 'Oromo-AfaanOromo',
	os: 'Ossetian',
	pi: 'Pali',
	ps: 'Pashto',
	fa: 'Persian',
	pl: 'Polish',
	pt: 'Portuguese',
	pa: 'Punjabi',
	qu: 'Quechua',
	rm: 'Romansh',
	ro: 'Romanian',
	ru: 'Russian',
	se: 'Sami',
	sm: 'Samoan',
	sg: 'Sango',
	sa: 'Sanskrit',
	sr: 'Serbian',
	sh: 'Serbo-Croatian',
	st: 'Sesotho',
	tn: 'Setswana',
	sn: 'Shona',
	sd: 'Sindhi',
	si: 'Sinhalese',
	ss: 'Siswati',
	sk: 'Slovak',
	sl: 'Slovenian',
	so: 'Somali',
	nr: 'Southern-Ndebele',
	es: 'Spanish',
	su: 'Sundanese',
	sw: 'Swahili',
	sv: 'Swedish',
	tl: 'Tagalog',
	ty: 'Tahitian',
	tg: 'Tajik',
	ta: 'Tamil',
	tt: 'Tatar',
	te: 'Telugu',
	th: 'Thai',
	bo: 'Tibetan',
	ti: 'Tigrinya',
	to: 'Tonga',
	ts: 'Tsonga',
	tr: 'Turkish',
	tk: 'Turkmen',
	tw: 'Twi',
	ug: 'Uyghur',
	uk: 'Ukrainian',
	ur: 'Urdu',
	uz: 'Uzbek',
	ve: 'Venda',
	vi: 'Vietnamese',
	vo: 'Volapük',
	wa: 'Wallon',
	cy: 'Welsh',
	wo: 'Wolof',
	fy: 'Western-Frisian',
	xh: 'Xhosa',
	yi: 'Yiddish',
	yo: 'Yoruba',
	za: 'Zhuang',
	zu: 'Zulu',
}

export const sortDescByKey = (key, items) => (items?.length ? items.sort((a, b) => b[key] - a[key]) : [])

const getKeyByValue = (object, value) => {
	return Object.keys(object).find((key) => object[key] === value)
}

export const getLanguageCode = (value) => {
	return getKeyByValue(LANGUAGE_CODES, value)
}

const getSelectedSubOption = (option, audioLanguage, bandwidth) => {
	let selectedSubOption = null
	let subOption = null
	if (option?.mainHeading === 'Audio Language') {
		subOption = option?.subOptions?.find((item) => item.language === audioLanguage)
	} else if (option?.mainHeading === 'Streaming Quality') {
		subOption = option?.subOptions?.find((item) => item.value === bandwidth)
	}
	/*
	 * classification key is only found in subOptions for 'Streaming Quality' option
	 */
	if (subOption) {
		selectedSubOption = {
			optionId: option.id,
			id: subOption?.id,
			value: subOption.classification || subOption.title,
		}
	}
	return selectedSubOption
}

export const getDefaultSubOptionsValues = (playerSettingsOptions, audioLanguage, bandwidth) => {
	let defaultValues = []
	if (playerSettingsOptions.length) {
		defaultValues = playerSettingsOptions.map((option) => {
			let defaultValue = null
			if (audioLanguage || bandwidth) {
				defaultValue = getSelectedSubOption(option, audioLanguage, bandwidth)
			} else {
				defaultValue = {
					optionId: option.id,
					id: option.subOptions?.[0]?.id,
					value: option.subOptions?.[0]?.classification || option.subOptions?.[0]?.title,
				}
			}
			return defaultValue
		})
	}
	return defaultValues
}

export const PLAYER_MODAL_OPTIONS = [
	{ id: 1, mainHeading: 'Streaming Quality' },
	{ id: 2, mainHeading: 'Audio Language' },
	// { id: 3, mainHeading: 'Subtitle Language' },
]

export const getPlayerSettingsOptions = (config, audioLanguages) => {
	const videoQualityItems = getConfigPayloadValue(config, 'video_quality', 'items')
	let supportedBandwidths = JSON.parse(videoQualityItems)

	const modalOptions = PLAYER_MODAL_OPTIONS.map((item) => {
		if (item.mainHeading === 'Streaming Quality') {
			item.subOptions = supportedBandwidths
		} else if (item.mainHeading === 'Audio Language') {
			let supportedAudioLanguages = audioLanguages?.map((audioLanguage) => {
				const title = audioLanguage.label || LANGUAGE_CODES[audioLanguage.language] || 'Unknown'
				return { ...audioLanguage, id: audioLanguage.label || audioLanguage.language, title }
			})
			item.subOptions = supportedAudioLanguages
		}
		return item
	})
	return modalOptions
}
export const convertSecondsToTime = (seconds) => {
	return moment.utc(seconds * 1000).format(seconds > 3600 ? 'HH:mm:ss' : 'mm:ss')
}
export const getCurrentEpgEvent = (channelsState, channelTag) => {
	const channelEpg = channelsState?.channels?.filter((channel) => channel.tag === channelTag)
	let currentTime = new Date()
	if (channelEpg[0]?.events?.length > 0) {
		for (let i = 0; i < channelEpg[0].events.length; i++) {
			let eventStartTime
			let eventEndTime
			if (channelEpg[0].events[i].startDateTime || channelEpg[0].events[i].endDateTime) {
				eventStartTime = new Date(channelEpg[0].events[i].startDateTime)
				eventEndTime = new Date(channelEpg[0].events[i].endDateTime)
			}
			if (eventStartTime < currentTime && currentTime < eventEndTime) {
				return channelEpg[0].events[i]
			}
		}
	}
	return null
}
