import { forkJoin } from 'rxjs'
import { catchError, map, takeUntil, timeout } from 'rxjs/operators'
import { ENV } from './environment'
import { ErrorMessages } from './errorCodeHelper'
import { ACTION_ERROR_CODE as ERROR_CODE } from '../slices/actions'
import { fromPromiseWorker } from './fromWorkerHelper'
import { getIn } from './objHelper'

const SKIP_URL = [ENV.SHOWMAX_CATEGORY_LINK, ENV.VERIFY_OTP]
function inUnmodifiableURLList(url) {
	return SKIP_URL.includes(url)
}

function shouldModifyURL(url, isAbsoluteURL) {
	if (isAbsoluteURL || inUnmodifiableURLList(url)) {
		return false
	} else {
		return url.indexOf(ENV.BASE_URL) === -1
	}
}

const Endpoint = (endpoint, isAbsoluteURL = false) => {
	return shouldModifyURL(endpoint, isAbsoluteURL) ? ENV.BASE_URL + endpoint : endpoint
}
export const HTTP = {
	GET: (URL, Headers, ResponseCb, ErrorCb, isAbsoluteURL) =>
		fromPromiseWorker.getJSON(Endpoint(URL, isAbsoluteURL), Headers).pipe(map(ResponseCb), catchError(ErrorCb)),
	GET_WITH_CANCEL_AND_TIMEOUT: (URL, Headers, ResponseCb, ErrorCb, isAbsoluteURL, cancelUntil, timeOutInMs) =>
		fromPromiseWorker
			.getJSON(Endpoint(URL, isAbsoluteURL), Headers)
			.pipe(map(ResponseCb), timeout(timeOutInMs), takeUntil(cancelUntil), catchError(ErrorCb)),
	GET_WITH_CANCEL: (URL, Headers, ResponseCb, ErrorCb, isAbsoluteURL, cancelUntil) =>
		fromPromiseWorker
			.getJSON(Endpoint(URL, isAbsoluteURL), Headers)
			.pipe(map(ResponseCb), takeUntil(cancelUntil), catchError(ErrorCb)),
	POST: (URL, Body, Headers, ResponseCb, ErrorCb, isAbsoluteURL) =>
		fromPromiseWorker.post(Endpoint(URL, isAbsoluteURL), Body, Headers).pipe(map(ResponseCb), catchError(ErrorCb)),
	POST_WITH_TIMEOUT: (URL, Body, Headers, ResponseCb, ErrorCb, isAbsoluteURL, timeOutInMs) =>
		fromPromiseWorker
			.post(Endpoint(URL, isAbsoluteURL), Body, Headers)
			.pipe(map(ResponseCb), timeout(timeOutInMs), catchError(ErrorCb)),
	POST_WITH_CANCEL: (URL, Body, Headers, ResponseCb, ErrorCb, isAbsoluteURL, cancelUntil) =>
		fromPromiseWorker
			.post(Endpoint(URL, isAbsoluteURL), Body, Headers)
			.pipe(map(ResponseCb), takeUntil(cancelUntil), catchError(ErrorCb)),
	PUT: (URL, Body, Headers, ResponseCb, ErrorCb) =>
		fromPromiseWorker.put(Endpoint(URL), Body, Headers).pipe(map(ResponseCb), catchError(ErrorCb)),
	PUT_WITH_CANCEL: (URL, Body, Headers, ResponseCb, ErrorCb, cancelUntil) =>
		fromPromiseWorker
			.put(Endpoint(URL), Body, Headers)
			.pipe(map(ResponseCb), takeUntil(cancelUntil), catchError(ErrorCb)),
	PATCH: (URL, Body, Headers, ResponseCb, ErrorCb) =>
		fromPromiseWorker.patch(Endpoint(URL), Body, Headers).pipe(map(ResponseCb), catchError(ErrorCb)),
	DELETE: (URL, Body, Headers, ResponseCb, ErrorCb, isAbsoluteURL) =>
		fromPromiseWorker({
			method: 'DELETE',
			url: Endpoint(URL, isAbsoluteURL),
			body: Body,
			headers: Headers,
		}).pipe(map(ResponseCb), catchError(ErrorCb)),
	DELETE_WITH_CANCEL: (URL, Body, Headers, ResponseCb, ErrorCb, isAbsoluteURL, cancelUntil) =>
		fromPromiseWorker({
			method: 'DELETE',
			url: Endpoint(URL, isAbsoluteURL),
			body: Body,
			headers: Headers,
		}).pipe(map(ResponseCb), takeUntil(cancelUntil), catchError(ErrorCb)),
	FORK_JOIN: (Ajax$, ResponseCb, ErrorCb) => forkJoin(Ajax$).pipe(map(ResponseCb), catchError(ErrorCb)),
}

export const successResponse = (response) => ({
	data: response,
	error: null,
})

export const internetIsConnected = () => navigator.onLine

export const errorResponse = (response, action = {}) => {
	let serverError = response.response || response || ''
	let { status: statusCode = '0' } = response
	let type = getIn(action, 'payload.filter.type', getIn(action, 'type'))
	return {
		data: action.payload,
		serverError,
		errorCode: statusCode,
		errorShortCode: ERROR_CODE[type],
		error: !internetIsConnected()
			? ErrorMessages.getErrorMessageByName('network')
			: ErrorMessages.getErrorMessageByStatusCode(statusCode, serverError, action),
	}
}

export function fetchWithTimeout(resource, options = {}, timeout = 2000) {
	return Promise.race([
		fetch(resource, options),
		new Promise((_, reject) => setTimeout(() => reject(new Error('Timeout')), timeout)),
	])
}
