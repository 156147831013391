import { createSlice } from '@reduxjs/toolkit'
import { getAuthorizationHeaders, getHeaders, handleUnauthorized } from '../../utils/authHelper'
import { ENV } from '../../utils/environment'
import { errorResponse, HTTP, successResponse } from '../../utils/httpHelper'
import { mergeMap } from 'rxjs/operators'
import { of } from 'rxjs'
import { ofType } from 'redux-observable'
import { secureSessionStorage } from '../../utils/secureStorage'
import { ACTIVE_PROFILE_ID } from '../../utils/constants'

import { getIn } from '../../utils/objHelper'

export const PROFILE_FEATURE_KEY = 'profile'
/*
 * Create profile slice
 */
export const profileSlice = createSlice({
	name: PROFILE_FEATURE_KEY,
	initialState: {},
	reducers: {
		GET_PROFILES_LIST: (state) => {
			delete state.error
			delete state.serverError
			state.data = {}
			state.loading = true
		},
		GET_PROFILES_LIST_SUCCESS: (state, action) => {
			state.loading = false
			state.data = action.payload.data
		},
		GET_PROFILES_LIST_ERROR: (state, action) => {
			console.log(action)
			state.loading = false
			state.error = action.payload.error
			state.serverError = action.payload.serverError
		},
		SET_ACTIVE_PROFILE_ID: (state, action) => {
			state.data?.items?.forEach((item) => {
				if (item.id === action.payload.id) {
					item.active = true
					secureSessionStorage.setItem(ACTIVE_PROFILE_ID, item.id)
				} else {
					delete item.active
				}
			})
		},
		DELETE_PROFILE_FLAGS: (state, action) => {
			delete state.profileCrud
			// eslint-disable-next-line array-callback-return
			state.data?.items?.find((item) => {
				if (item.active) {
					secureSessionStorage.removeItem(ACTIVE_PROFILE_ID)
					delete item.active
				}
			})
		},
		EDIT_PROFILE: (state, action) => {
			delete state.error
			delete state.serverError
			state.loading = true
		},
		EDIT_PROFILE_SUCCESS: (state, action) => {
			state.loading = false
			state.profileCrud = true
			const savedItem = state.data?.items?.find((item) => item.id === action.payload.data.id)
			savedItem.alias = action.payload.data.alias
			savedItem.avatar = action.payload.data.avatar
		},
		EDIT_PROFILE_ERROR: (state, action) => {
			state.loading = false
			state.error = action.payload.error //getErrorMessage('profile_name_exists', subHeading, '/profiles/edit/name/' + profileId)
			state.serverError = action.payload.serverError
		},
		DELETE_PROFILE: (state, action) => {
			delete state.error
			delete state.serverError
			state.loading = true
			state.profileCrud = false
		},
		DELETE_PROFILE_SUCCESS: (state, action) => {
			state.loading = false
			state.profileCrud = true
			state.data?.items && (state.data.items = state.data.items.filter((item) => item.id !== action.payload.profileId))
			delete state.focusedProfileId
		},
		DELETE_PROFILE_ERROR: (state, action) => {
			state.error = action.payload.error
			state.serverError = action.payload.serverError
			state.loading = false
		},
		CREATE_PROFILE: (state, action) => {
			delete state.error
			delete state.serverError
			state.loading = true
			state.profileCrud = false
		},
		CREATE_PROFILE_SUCCESS: (state, action) => {
			state.loading = false
			state.profileCrud = true
			state.data = action.payload.data
			state.focusedProfileId = action.payload.data.id
		},
		CREATE_PROFILE_ERROR: (state, action) => {
			state.loading = false
			state.error = action.payload.error
			state.serverError = action.payload.serverError
		},
		REMOVE_PROFILE_CRUD: (state, action) => {
			delete state.profileCrud
		},
		REMOVE_PROFILE_ERROR: (state, action) => {
			state.loading = false
			state.error = action.payload.error
			state.serverError = action.payload.serverError
		},
		CLEAR_PROFILE_ERROR: (state, action) => {
			delete state.error
			delete state.serverError
		},
		SET_NEW_PROFILE_DETAILS: (state, action) => {
			state.newProfile = { ...state.newProfile, ...action.payload.newProfile }
		},
		CLEAR_NEW_PROFILE_DETAILS: (state, action) => {
			delete state.newProfile
		},
		SET_FOCUSED_PROFILE: (state, action) => {
			state.focusedProfileId = action.payload.id
		},
		REMOVE_FOCUSED_PROFILE: (state, action) => {
			delete state.focusedProfileId
		},
	},
})
/*
 * Export reducer for store configuration.
 */
export const profileReducer = profileSlice.reducer

/*
 * Export actions
 */
export const {
	GET_PROFILES_LIST,
	GET_PROFILES_LIST_SUCCESS,
	GET_PROFILES_LIST_ERROR,
	SET_ACTIVE_PROFILE_ID,
	DELETE_PROFILE_FLAGS,
	EDIT_PROFILE,
	EDIT_PROFILE_SUCCESS,
	EDIT_PROFILE_ERROR,
	DELETE_PROFILE,
	DELETE_PROFILE_SUCCESS,
	DELETE_PROFILE_ERROR,
	CREATE_PROFILE,
	CREATE_PROFILE_SUCCESS,
	CREATE_PROFILE_ERROR,
	REMOVE_PROFILE_CRUD,
	REMOVE_PROFILE_ERROR,
	CLEAR_PROFILE_ERROR,
	SET_NEW_PROFILE_DETAILS,
	CLEAR_NEW_PROFILE_DETAILS,
	SET_FOCUSED_PROFILE,
	REMOVE_FOCUSED_PROFILE,
} = profileSlice.actions
/*
 * Set up the redux-observable epic
 */
export const profileEpic = (action$) =>
	action$.pipe(
		ofType(GET_PROFILES_LIST.type, CREATE_PROFILE.type, EDIT_PROFILE.type, DELETE_PROFILE.type),
		mergeMap(profileService(action$))
	)

/*
 * Do API calls
 */
const profileService = (action$) => (action) => {
	switch (action.type) {
		case GET_PROFILES_LIST.type:
			return HTTP.GET(ENV.GET_PROFILES_LIST, getHeaders(), profilesSuccess, profilesError(action))
		case EDIT_PROFILE.type: {
			let editProfileUrl = ENV.UPDATE_OR_DELETE_PROFILE.replace('{PROFILE_ID}', action.payload.profileId)
			return HTTP.PUT_WITH_CANCEL(
				editProfileUrl,
				action.payload,
				getHeaders(),
				editProfilesSuccess,
				editProfileError(action),
				action$.pipe(ofType(EDIT_PROFILE.type))
			)
		}
		case DELETE_PROFILE.type: {
			let deleteProfileUrl = ENV.UPDATE_OR_DELETE_PROFILE.replace('{PROFILE_ID}', action.payload.profileId)

			return HTTP.DELETE_WITH_CANCEL(
				deleteProfileUrl,
				null,
				getHeaders(),
				deleteProfilesSuccess(action),
				deleteProfileError(action),
				false,
				action$.pipe(ofType(EDIT_PROFILE.type, GET_PROFILES_LIST.type))
			)
		}

		case CREATE_PROFILE.type: {
			let createProfileUrl = ENV.CREATE_PROFILE
			return HTTP.POST_WITH_CANCEL(
				createProfileUrl,
				action.payload,
				getAuthorizationHeaders(),
				createProfileSuccess,
				createProfileError(action),
				'',
				action$.pipe(ofType(CREATE_PROFILE.type, GET_PROFILES_LIST.type))
			)
		}
	}
}

const profilesSuccess = (response) => {
	return {
		type: GET_PROFILES_LIST_SUCCESS.type,
		payload: successResponse(response),
	}
}

const profilesError = (action) => (response) => {
	return of(
		handleUnauthorized(
			response,
			{
				type: GET_PROFILES_LIST_ERROR.type,
				payload: errorResponse(response, action),
			},
			action
		)
	)
}

const editProfilesSuccess = (response) => {
	const successResp = successResponse(response.response || response)
	return {
		type: EDIT_PROFILE_SUCCESS.type,
		payload: { ...successResp },
	}
}

const editProfileError = (action) => (response) => {
	return of(
		handleUnauthorized(
			response,
			{
				type: EDIT_PROFILE_ERROR.type,
				payload: errorResponse(response, action),
			},
			action
		)
	)
}

const deleteProfilesSuccess = (action) => (response) => {
	return {
		type: DELETE_PROFILE_SUCCESS.type,
		payload: action.payload,
	}
}

const deleteProfileError = (action) => (response) => {
	return of(
		handleUnauthorized(
			response,
			{
				type: DELETE_PROFILE_ERROR.type,
				payload: errorResponse(response, action),
			},
			action
		)
	)
}

const createProfileSuccess = (response) => {
	const successResp = successResponse(response.response || response)
	return {
		type: CREATE_PROFILE_SUCCESS.type,
		payload: { ...successResp },
	}
}

const createProfileError = (action) => (response) => {
	return of(
		handleUnauthorized(
			response,
			{
				type: CREATE_PROFILE_ERROR.type,
				payload: errorResponse(response, action),
			},
			action
		)
	)
}
