import { nanoid } from '@reduxjs/toolkit'
import { isArray, isCloneable, isFunction, isInteger, isObject, isString } from './isHelper'

export const REGEX_INT = /^[-\d]+$/g
export const REGEX_PROP_PATH_SEP = /[.[\]]+?/gi

export const asInteger = (c) => (REGEX_INT.test(c) && parseInt(c, 10)) || c
export const keySplit = (keys, regex = REGEX_PROP_PATH_SEP) =>
	(isArray(keys) ? keys : `${keys}`.split(regex)).map(asInteger)
export const getMsgID = (prefix = 'MSG', base = 36, sub = 3, sep = '_') => {
	const mulAdd = (a = new Date().getTime(), b = Math.random()) => (a + a * b).toString(base).replace('.', '')
	let rand = ''
	while (rand.length <= sub) {
		rand = `${rand}${sep}${mulAdd()}`
	}
	return [prefix, rand.substring(sub)].join(sep)
}
export const has = (obj, key) =>
	isArray(obj) ? asInteger(key) < obj.length : Object.prototype.hasOwnProperty.call(obj, key)
const getFromArray = (a, index) => {
	let ret = null
	if (isInteger(index) && isArray(a)) {
		ret = a[index % a.length]
	}
	return ret
}

const getFromObject = (a, c) => (isObject(a) && c in a && a[c]) || null

export const getBy = (keys, _def = null) => {
	let _skeys = keySplit(keys)
	let _getFn = (a, c) => getFromArray(a, c) || getFromObject(a, c) || undefined
	return (obj, _default = _def) => (!!obj && _skeys.reduce(_getFn, obj)) || _default
}

export const get = (obj, keys, _default = null) => getBy(keys)(obj, _default)
export const getIn = (obj, keys, _default = null) => getBy(keys)(obj, _default)
export const setIn = (subject, keys, value = {}, merger = (r, v) => (isArray(r) ? r.concat([v]) : [r, v])) => {
	keys = isArray(keys) ? keys : keys.split(/[.[\]]+?/gi)
	if (keys.length === 0) {
		return
	}
	for (let i = 0, n = keys.length - 1; i < n; ++i) {
		const key = keys[i]
		const tmp = get(subject, key)
		if (tmp) {
			subject = tmp
		} else {
			subject = setIn(subject, key, {})
		}
	}
	let key = keys[keys.length - 1]
	let result = get(subject, key)
	subject[key] = result ? merger(result, value) : value

	return get(subject, key)
}
export const findInObject = (obj, prop) => {
	for (const key in obj) {
		if (key === prop) {
			return obj[key]
		} else if (typeof obj[key] === 'object') {
			const result = findInObject(obj[key], prop)

			if (result !== null) {
				return result
			}
		}
	}

	return null
}
