export const termsDialog = {
	mainHeading: "You haven't accepted the Ts & Cs",
	body: 'You will need to accept the Ts & Cs in order to continue. View Ts & Cs at dstv.com/tscs',
	buttons: [
		{
			title: 'Accept',
			action: 'custom',
		},
	],
}

export const reloadAppDialog = {
	mainHeading: 'Whoops',
	body: 'Looks like something went wrong. Please try again.',
	buttons: [
		{
			title: 'Reload',
			action: 'custom',
		},
	],
}
