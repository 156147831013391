import { AvatarList, Loader } from '@dstv-web-leanback/dstv-frontend-components'
import styles from './ProfileNew.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import {
	AVATARS_FEATURE_KEY,
	CLEAR_AVATARS,
	GET_AVATARS,
	SET_NEW_PROFILE_DETAILS,
	useNavigation,
} from '@dstv-web-leanback/dstv-frontend-services'
import { useEffect } from 'react'
import { FocusContext, useFocusable } from '@connected-video-web/norigin-spatial-navigation'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import dstvLogo from '../../../../assets/images/dstv_logo_fallback.png'

export function ProfileNew(props) {
	const location = useLocation()
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const { navigateBack } = useNavigation()
	const fromEditPage = location.pathname.replace('/profiles/new', '')
	const { id } = useParams()

	const { focusKey, ref } = useFocusable({
		focusable: true,
		isFocusBoundary: true,
		onBackPress: () => {
			if (fromEditPage) {
				navigateBack()
			} else {
				navigate(`/profiles`)
			}
		},
	})

	const avatars = useSelector((state) => state[AVATARS_FEATURE_KEY].data?.items)

	useEffect(() => {
		dispatch(GET_AVATARS())
		return () => {
			dispatch(CLEAR_AVATARS())
		}
	}, [dispatch])

	return !avatars ? (
		<Loader />
	) : (
		<div className={styles.component}>
			<img className={styles.dstv_logo} alt="DStv logo" src={dstvLogo} />
			<div className={styles.title}>Choose your avatar</div>
			<FocusContext.Provider value={focusKey}>
				<div ref={ref}>
					{avatars && (
						<AvatarList
							type="new"
							setTitleKey={() => {
								return
							}}
							avatars={avatars}
							setSelectedAvatarId={(avatarId) => {
								return
							}}
							onSelectItem={(avatarId, avatarUri) => {
								dispatch(SET_NEW_PROFILE_DETAILS({ newProfile: { avatarId, avatarUri } }))
								navigate('/profiles/edit/name/' + id)
							}}
						/>
					)}
				</div>
			</FocusContext.Provider>
		</div>
	)
}
export default ProfileNew
