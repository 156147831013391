import { FocusContext, useFocusable } from '@connected-video-web/norigin-spatial-navigation'
import styles from './Profile.module.scss'
import { ProfileEditIcon } from './ProfileEditIcon'
import { ProfileImage } from '../ProfileImage/ProfileImage'

export function Profile(props) {
	const {
		profile: { alias, avatar },
		onSelectItem,
		onEditItem,
		avatarProps,
		index,
	} = props
	const { ref, focusKey, hasFocusedChild } = useFocusable({
		trackChildren: true,
		focusKey: props.focusKey,
	})

	return (
		<FocusContext.Provider value={focusKey}>
			<div ref={ref} className={styles['container']} key={alias + avatar?.id}>
				<div className={[styles.avatar__title, hasFocusedChild ? styles.active : ''].join(' ')}>{alias}</div>
				<ProfileImage
					imageType="profile"
					alias={alias}
					avatarUri={avatar?.uri?.replace(',f_auto', '')}
					isEditFocused={hasFocusedChild}
					onSelectItem={onSelectItem}
					onItemFocused={() => {
						return
					}}
					avatarProps={avatarProps}
					index={index}
				/>
				{hasFocusedChild && <ProfileEditIcon onEditItem={onEditItem} />}
			</div>
		</FocusContext.Provider>
	)
}
export default Profile
