import { useEffect, useRef, useState } from 'react'

import { useFocusable, FocusContext } from '@connected-video-web/norigin-spatial-navigation'

import {
	Card16x9,
	CardApps,
	CardIplate,
	CardPortrait,
	CardChannelSubnav,
} from '@dstv-web-leanback/dstv-frontend-components'
import styles from './Carousel.module.scss'

export function Carousel({ cards, carouselIndex, onCarouselFocus, title, type, ...rest }) {
	const { focusKey, ref, hasFocusedChild } = useFocusable({
		saveLastFocusedChild: rest.saveLastFocusedChild ? true : false,
		trackChildren: true,
	})

	const carouselWrapper = useRef()
	const [carouselScroll, setCarouselScroll] = useState(0)

	const changeCarouselFocus = (focusedRef, item, index) => {
		if (focusedRef.current) {
			setCarouselScroll(focusedRef.current.offsetLeft - Number.parseInt(getComputedStyle(ref.current).paddingLeft))
			onCarouselFocus && onCarouselFocus(carouselWrapper.current, carouselIndex, item, rest.id, index)
		}
	}

	const cardComponents = {
		apps: CardApps,
		iplate: CardIplate,
		channels: Card16x9,
		vod: CardPortrait,
		channelgroups: CardChannelSubnav,
	}

	const Card = cardComponents[type] ? cardComponents[type] : CardPortrait

	return (
		<FocusContext.Provider value={focusKey}>
			<div ref={carouselWrapper} className={styles.carousel_wrapper}>
				{title && <h3 className={`${styles.title} ${hasFocusedChild && styles.active}`}>{title}</h3>}
				<div ref={ref} className={styles.carousel} style={{ transform: `translate3d(-${carouselScroll}px,0,0)` }}>
					{cards.map((item, index) => {
						return (
							<Card
								index={index}
								item={item}
								itemFocus={changeCarouselFocus}
								key={index}
								rowId={rest.id}
								rowTitle={title}
								{...rest}
							/>
						)
					})}
				</div>
			</div>
		</FocusContext.Provider>
	)
}
export default Carousel
