import React, { Suspense } from 'react'
import { Route, Routes } from 'react-router-dom'
import { PageWrapper, Loader } from '@dstv-web-leanback/dstv-frontend-components'

import { Menu } from '..'
import { Search } from '../../pages/Search/Search'
import Watchlist from '../../pages/Watchlist/Watchlist'

const Catchup = React.lazy(() => import('../../pages/Catchup/Catchup'))
const Home = React.lazy(() => import('../../pages/Home/Home'))
const Channel = React.lazy(() => import('../../pages/Channel/Channel'))

export const Router = ({ activeProfile }) => {
	return (
		<PageWrapper>
			<Menu activeProfile={activeProfile} />

			<Suspense fallback={<Loader />}>
				<Routes>
					<Route key="Home" path={'/home'} element={<Home />} />
					<Route key="LiveTv" path="/livetv/" element={<Channel />} />
					{['/tvshows/*', '/movies/*', '/sport/*', '/kids/*', '/catchup/*', '/channelgroups/*'].map((path) => (
						<Route key="Catchup" path={path} element={<Catchup path={path} />} />
					))}
					<Route path="/mylist" element={<Watchlist />} />
					<Route path="/search" element={<Search />} />
				</Routes>
			</Suspense>
		</PageWrapper>
	)
}

export default Router
