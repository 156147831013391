import { AnalyticsBrowser } from '@segment/analytics-next'
import { getSegmentId, DEV_MODE } from '../utils/environment'
import { createLogger } from '../utils/utils'
export const analyticsPrint = createLogger('Analytics', '#0898EF')
export const analytics = new AnalyticsBrowser()
analytics.load({ writeKey: getSegmentId() }).catch((err) => DEV_MODE && console.error(err))
export const segmentAnalytics = analytics

analytics.on('error', (err) => analyticsPrint.error(err))

// analytics.on('identify', (ctx) => console.log(ctx))

// analytics.on('track', (ctx) => console.log(ctx))
