import { createSlice } from '@reduxjs/toolkit'
import { mergeMap, filter } from 'rxjs/operators'
import { of } from 'rxjs'

import { ENV } from '../../utils/environment'
import { errorResponse, HTTP } from '../../utils/httpHelper'
import { getHeaders, handleUnauthorized } from '../../utils/authHelper'
import { PLAY } from '../player/player.slice'

export const CDN_AUTH_FEATURE_KEY = 'cdn_auth'

const initialState = {
	loading: true,
	accessToken: null,
	channelTag: null,
}
/*
 * Create our slice
 */
export const cdnAuthSlice = createSlice({
	name: CDN_AUTH_FEATURE_KEY,
	initialState: initialState,
	reducers: {
		GET_CDN_TOKEN: (state) => {
			Object.assign(state, initialState)
		},
		RESET_CDN_TOKEN: (state) => {
			Object.assign(state, initialState)
		},
		GET_CDN_TOKEN_SUCCESS: (state, action) => {
			state.loading = false
			state.accessToken = action.payload.accessToken
			state.channelTag = action.payload.channelTag
		},
		GET_CDN_TOKEN_ERROR: (state, action) => {
			state.error = action.payload.error
			state.serverError = action.payload.serverError
			state.loading = false
		},
	},
	extraReducers: (builder) => {
		builder.addCase(PLAY.type, (state) => {
			delete state.error
			delete state.serverError
		})
	},
})

/*
 * Export reducer for store configuration.
 */
export const cdnAuthReducer = cdnAuthSlice.reducer

/*
 * Export actions
 */
export const { GET_CDN_TOKEN, RESET_CDN_TOKEN, GET_CDN_TOKEN_SUCCESS, GET_CDN_TOKEN_ERROR } = cdnAuthSlice.actions

/*
 * Set up the redux-observable epic
 */
export const cdnAuthEpic = (action$) => action$.pipe(filter(GET_CDN_TOKEN.match), mergeMap(cdnAuthService(action$)))

/*
 * Do API calls
 */
const cdnAuthService = (action$) => (action) => {
	switch (action.type) {
		case GET_CDN_TOKEN.type:
			return HTTP.POST(
				ENV.GET_CDN_ACCESS_TOKEN.replace('{channel_tag}', action.payload.channelTag),
				{},
				getHeaders(),
				getCDNTokenSuccess(action),
				getCDNTokenError(action),
				true
			)
	}
}

/*
 * Dispatch actions based on API responses
 */
const getCDNTokenSuccess = (action) => (response) => {
	return {
		type: GET_CDN_TOKEN_SUCCESS.type,
		payload: {
			accessToken: (response?.response || response)?.access_token,
			channelTag: action.payload.channelTag,
		},
	}
}

const getCDNTokenError = (action) => (response) => {
	return of(
		handleUnauthorized(
			response,
			{
				type: GET_CDN_TOKEN_ERROR.type,
				payload: errorResponse(response, action),
			},
			action
		)
	)
}
