export const SESSION_ID = 'sessionID'
export const SUBSCRIPTION_PACKAGE = 'userPackage'
export const USER_ID = 'userID'
export const HARDWARE_ID = 'hardwareID'
export const DEVICE_ID = 'deviceID'
export const TOKEN_ID = 'tokenID'
export const CONNECT_ID = 'CONNECT_ID'
export const AUTH_KEY = 'authKEY'
export const PUSHER_KEY = '5b1cf858986ab7d6a9d7'
export const FALLBACK_PROFILE_ID = 'FALLBACK_PROFILE_ID'
export const ACTIVE_PROFILE_ID = 'x-profile-id'
export const WATCH_LIST_ID = '8fefdac3-daff-45f9-8262-620b2b5ab300'

export const deviceRegistered = { value: 'This device is registered' }
export const deviceNotRegistered = { value: 'This device is not registered. Press OK to register.' }
export const thisDevice = { value: 'This is current Device' }
export const entitlementErrors = { maxDevice: 'Maximum number of devices reached' }
export const CLOUD_DVR_KEY = 'CLOUD_DVR'

// Live TV
export const HOUR_WIDTH = 660
export const BREAKPOINT_ICON_ONLY = 267
export const BREAKPOINT_NO_CONTENT = 91

export const pageType = {
	home: 'home',
	channels: 'livetv',
	catchup: 'catchup',
	tvshows: 'tvshows',
	movies: 'movies',
	sport: 'sport',
	kids: 'kids',
	tvguide: 'tvguide',
	zatvguide: 'zatvguide',
	watchlist: 'watchlist',
	showmax: 'showmax',
	tv: 'tv',
	profiles: 'profiles',
	applock: 'applock',
	signIn: 'Sign In',
	signUP: 'Sign Up',
	linkmydstvaccount: 'linkmydstvaccount',
	settings: 'settings',
	menu: 'menu',
	avatar: 'avatar',
	search: 'search',
	staticPage: 'rwc-static',
	apps: 'apps',
	channelGroups: 'channelgroups',
}

export const fallbackChannelEvent = {
	id: '',
	genRef: '',
	mainContentID: '',
	title: 'Event information is not currently available',
	episodeTitle: '',
	longSynopsis: '',
	seasonNumber: '',
	episodeNumber: '',
	isLive: false,
	startDateTime: '',
	endDateTime: '',
	duration: '01:00:00',
	maturityClassification: '',
	rating: '',
	year: 0,
	genres: [],
	genreGroup: '',
	secondaryGenre: '',
	primaryGenre: '',
	tertiaryGenre: '',
	channelTag: '',
	thumbnailImagePaths: {},
	blockStream: false,
	scheduleId: '',
	associatedChannels: [],
	adRequest: {},
	metaData: [],
}
