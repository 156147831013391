import { useEffect, useState } from 'react'
import { FocusContext, useFocusable, setFocus } from '@connected-video-web/norigin-spatial-navigation'

import PlayerSettingsModalItem from '../PlayerSettingsModalItem/PlayerSettingsModalItem'
import PlayerSettingsSubOptions from '../PlayerSettingsSubOptions/PlayerSettingsSubOptions'
import styles from './PlayerSettingsModal.module.scss'

export function PlayerSettingsModal({ options, defaultValues = [], handleBackButton, handleSubOptionSelection }) {
	const { focusKey, ref, focusSelf } = useFocusable({
		onBackPress: () => {
			handleBackButton()
		},
	})
	const [selectedOption, setSelectedOption] = useState(null)
	const [selectedSubOption, setSelectedSubOption] = useState()

	useEffect(() => {
		focusSelf()
	}, [focusSelf])

	const getDefaultValue = (item) => {
		const defaultValue = defaultValues.find((d) => d.optionId === item?.id)
		return defaultValue?.value
	}

	const getDefaultSubOption = (item) => {
		return defaultValues.find((d) => d.optionId === item?.id)
	}

	const getSubOptions = (selectedOption) => {
		const option = options.find((item) => item.mainHeading === selectedOption.mainHeading)
		return option?.subOptions
	}

	const handleBack = () => {
		const focusId = 'PLAYER_MAIN_SETTINGS_' + selectedOption.id
		setSelectedOption(null)
		setFocus(focusId)
	}

	const setSubOption = (item) => {
		setSelectedSubOption(item)
		handleSubOptionSelection(selectedOption.mainHeading, item)
	}
	return (
		<FocusContext.Provider value={focusKey}>
			{options && options.length && (
				<div ref={ref} className={styles.playerSettingsModal} key={'playerSettingsModal-' + selectedOption}>
					<div className={styles.qualityContainer}>
						{!selectedOption &&
							options.map((item) => (
								<PlayerSettingsModalItem
									key={'PlayerSettingsModalItem-' + item?.id}
									item={item}
									defaultValue={getDefaultValue(item)}
									setSelectedOption={(i) => setSelectedOption(i)}
								/>
							))}
						{selectedOption && (
							<PlayerSettingsSubOptions
								option={selectedOption}
								subOptions={getSubOptions(selectedOption)}
								selectedSubOption={selectedSubOption}
								defaultSubOption={getDefaultSubOption(selectedOption)}
								setSelectedSubOption={setSubOption}
								handleBack={handleBack}
							/>
						)}
					</div>
				</div>
			)}
		</FocusContext.Provider>
	)
}
export default PlayerSettingsModal
