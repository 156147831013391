import styles from './InputKeyboard.module.scss'
import { useState } from 'react'
import PasswordHide from '../../../assets/images/smarttv/password-hide.svg'
import PasswordShow from '../../../assets/images/smarttv/password-show.svg'
import { useFocusable } from '@connected-video-web/norigin-spatial-navigation'

export function InputKeyboard({ type, errorMessage, value }) {
	const [showPassword, setShowPassword] = useState(false)

	const { ref, focused } = useFocusable()
	return (
		<div className={styles.input_keyboard}>
			{errorMessage && (
				<div key={errorMessage} className={styles.errorMessage}>
					{errorMessage}
				</div>
			)}
			<div ref={ref} className={styles.input_wrapper}>
				<span className={[styles.input, errorMessage ? styles.error : '', focused ? styles.active : ''].join(' ')}>
					<bdi className={type === 'password' && !showPassword ? styles.password : ''}>{value}</bdi>
				</span>
				{type === 'password' && (
					<span className={[styles.password_toggle].join(' ')}>
						<div
							style={{
								backgroundImage: `url(${showPassword ? PasswordHide : PasswordShow})`,
							}}
						></div>
					</span>
				)}
			</div>
		</div>
	)
}
export default InputKeyboard
