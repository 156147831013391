import styles from './DialogFull.module.scss'
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import { useFocusable, FocusContext } from '@connected-video-web/norigin-spatial-navigation'

import { Button } from '@dstv-web-leanback/dstv-frontend-components'
import { postAPIErrorToSegment } from '@dstv-web-leanback/dstv-frontend-middleware'

export function DialogFull({ backAction, customAction, error, isError = true, retryAction, serverError = '' }) {
	const { mainHeading, subHeading, body, buttons, size } = error

	const { focusKey, focusSelf, ref } = useFocusable({
		focusKey: 'ERROR_CONTROLS',
		forceFocus: true,
		isFocusBoundary: true,
		onBackPress: () => {
			backAction && backAction()
		},
	})
	const navigate = useNavigate()

	useEffect(() => {
		focusSelf()
		isError && postAPIErrorToSegment(error, serverError)
	}, [])

	const buttonAction = (button) => {
		switch (button?.action) {
			case 'navigate': {
				navigate(button.url, { replace: true })
				break
			}
			case 'custom': {
				customAction(button.url)
				break
			}
			case 'retry': {
				retryAction()
				break
			}
			case 'back': {
				backAction()
				break
			}
			default: {
				backAction()
			}
		}
	}

	return (
		<FocusContext.Provider value={focusKey}>
			<div ref={ref} className={styles.error_full}>
				<div className={styles.content_wrapper}>
					{mainHeading && <h2 className={styles.main_heading}>{mainHeading}</h2>}
					{subHeading && <h3 className={styles.sub_heading}>{subHeading}</h3>}
					{body &&
						size === 'large' &&
						body.map((message) => (
							<p key={message} className={styles.large}>
								{message}
							</p>
						))}
					{body && !size && <p className={styles.body}>{body}</p>}
				</div>
				<div className={styles.buttons_wrapper}>
					{buttons?.map((button) => {
						return (
							<Button
								id={button.title}
								key={button.title}
								onSelect={() => {
									buttonAction(button)
								}}
								title={button.title}
							/>
						)
					})}
				</div>
			</div>
		</FocusContext.Provider>
	)
}
export default DialogFull
