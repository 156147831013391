import { FocusContext, useFocusable } from '@connected-video-web/norigin-spatial-navigation'
import { useRef, useState } from 'react'
import Carousel from '../../collection/Carousel/Carousel'
import Synopsis from '../../container/Synopsis/Synopsis'
import styles from './Recommendation.module.scss'

export function Recommendation({ row, index, onRecommendationFocus }) {
	const { focusKey, ref } = useFocusable()
	const recommendationWrapper = useRef()

	const [currentIndex, setCurrentIndex] = useState(0)

	const getSynopsis = (cardIndex) => {
		const data = row.editorialItems[cardIndex]
		return {
			heading: data?.title,
			rating: data?.metaData[0]?.value,
			details: data?.synopsis,
		}
	}

	const onFocus = (focusedRef, _carouselIndex, _item, _id, index) => {
		setCurrentIndex(index)
		onRecommendationFocus(focusedRef)
	}

	return row?.editorialItems?.length ? (
		<FocusContext.Provider value={focusKey}>
			<div ref={recommendationWrapper}>
				<div ref={ref} className={styles['container']}>
					<Carousel
						cards={row.editorialItems}
						key={row.rank}
						title={row.name}
						type="vod"
						id={row.rank}
						carouselIndex={index}
						onCarouselFocus={onFocus}
					/>
					<Synopsis
						key={'Synopsis-' + currentIndex}
						data={row.editorialItems[currentIndex]}
						synopsis={getSynopsis(currentIndex)}
					/>
				</div>
			</div>
		</FocusContext.Provider>
	) : null
}
export default Recommendation
