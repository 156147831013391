import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useFocusable } from '@connected-video-web/norigin-spatial-navigation'

import { BOOKMARKS_FEATURE_KEY, getVodDetails, PLAY } from '@dstv-web-leanback/dstv-frontend-services'
import {
	convertSecondsToMins,
	getTimeInDisplayFormat,
	ProgressBar,
	iconWatchlistTick,
} from '@dstv-web-leanback/dstv-frontend-components'
import playicon from '../../../assets/images/play.png'
import styles from './EpisodeListItem.module.scss'
import { getProgressBarDuration } from './helpers'
import { useMemo } from 'react'

export function EpisodeListItem({ data, itemFocus, focusKey }) {
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const bookmarksState = useSelector((state) => state[BOOKMARKS_FEATURE_KEY])
	const bookmark = bookmarksState?.bookmarks?.find((item) => item.genref === data.genRef)
	const bookmarkDuration = useMemo(() => {
		return getProgressBarDuration(bookmark?.timeInSeconds, data?.durationInSeconds)
	}, [bookmark?.timeInSeconds, data?.durationInSeconds])

	const { displayItemTitle, episode, shortSynopsis, title } = data

	const { ref, focused } = useFocusable({
		focusKey,
		onFocus: () => {
			itemFocus(ref)
		},
		onEnterPress: () => {
			navigateToPlayer()
		},
	})

	const navigateToPlayer = () => {
		const playerDetails = getVodDetails(data)
		dispatch(PLAY({ ...playerDetails, ignoreBookmarks: bookmarkDuration >= 93 }))
		navigate('/play')
	}
	return (
		<div ref={ref} className={`${styles.episode_list_item} ${focused ? styles.focus : ''}`}>
			<div
				className={styles.episode_image_wrapper}
				style={{ backgroundImage: `url(${data?.images['play-image']?.MEDIUM})` }}
			>
				{bookmarkDuration >= 93 && (
					<div className={styles.episode_watched}>
						<img src={iconWatchlistTick} alt="" />
						<span>Watched</span>
					</div>
				)}
				{focused && <img src={playicon} alt="play" />}
				<footer>
					{!!episode && <div className={styles.episode_label}>Ep {episode}</div>}
					<div className={styles.progress_wrapper}>
						{!!bookmark?.timeInSeconds && <ProgressBar progress={bookmarkDuration} />}
					</div>
				</footer>
			</div>
			<div className={styles.episode_info_wrapper}>
				<header>
					<h3>{displayItemTitle}</h3>
					<span className={styles.episodeDuration}>
						{!!convertSecondsToMins(data.durationInSeconds) && `${convertSecondsToMins(data.durationInSeconds)} min`}
						{data?.restrictionRating?.ageRestrictedToAge && ` | ${data?.restrictionRating?.ageRestrictedToAge}`}
					</span>
					<p>{shortSynopsis}</p>
				</header>
				<footer>
					Aired: {getTimeInDisplayFormat(data.startDateTime)} | Available until:{' '}
					{getTimeInDisplayFormat(data.expiryDateTime)}
				</footer>
			</div>
		</div>
	)
}
export default EpisodeListItem
