import { createSlice } from '@reduxjs/toolkit';

export const KEYBOARD_FEATURE_KEY = 'keyboard';

/*
 * Create our slice
 */
export const keyboardSlice = createSlice({
	name: KEYBOARD_FEATURE_KEY,
	initialState: { value: "" },
	reducers: {
		SET_KEYBOARD_VALUE: (state, action) => {
			state.value = action.payload.value;
		},
		CLEAR_KEYBOARD: (state) => {
			state.value = '';
		}
	},
});

/*
 * Export reducer for store configuration.
 */
export const keyboardReducer = keyboardSlice.reducer;

/*
 * Export actions
 */
export const {
	SET_KEYBOARD_VALUE,
	CLEAR_KEYBOARD
} = keyboardSlice.actions;