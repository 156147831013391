import { createSlice } from '@reduxjs/toolkit'

export const NAV_FEATURE_KEY = 'nav'

/**
 * Nav Slice
 */
export const navSlice = createSlice({
	name: NAV_FEATURE_KEY,
	initialState: { history: [] },
	reducers: {
		NAV_PUSH: (state, action) => {
			state.history.forEach((o) => {
				if (o.pathname === action.payload.location) {
					o.focusKey = action.payload.key
				}
			})
		},
		NAV_POP: (state, action) => {
			delete state.history[state.history.length - 1].focusKey
		},
		NAV_REPLACE: (state, action) => {
			state.history[state.history.length - 1].focusKey = action.payload.key
		},
		ADD_NAV_LOCATION: (state, action) => {
			if (state.history[state.history.length - 1]?.pathname !== action.payload) {
				state.history = [...state.history, { pathname: action.payload, back: false }]
			}
		},
		REPLACE_NAV_LOCATIONS: (state, action) => {
			state.history = action.payload
		},
	},
})
/*
 * Export reducer for store configuration.
 */
export const navReducer = navSlice.reducer

/*
 * Export actions
 */
export const { NAV_PUSH, NAV_POP, NAV_REPLACE, ADD_NAV_LOCATION, REPLACE_NAV_LOCATIONS } = navSlice.actions
