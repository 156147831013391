import { useEffect, useState, useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { configKeyEnabled, getConfigPayloadValue } from '@dstv-web-leanback/dstv-frontend-services'

export const useCreditOverlay = (playNextEpisode, currentTime, video, videoDuration, onToggleCreditOverlay) => {
	const [showCreditOverlay, setShowCreditOverlay] = useState(false)
	const [dismissed, setDismissed] = useState(false)
	const [overlayStart, setOverlayStart] = useState(0)
	const [overlayEnd, setOverlayEnd] = useState(0)

	const configState = useSelector((state) => state.config)
	const settingState = useSelector((state) => state.settings)
	const { data: autoplayData } = useSelector((state) => state.autoplay)

	const overlayDuration = useMemo(() => getConfigPayloadValue(configState, 'autoplay_enabled', 'countdown') || 10, [])
	const autoplayEnabled = useMemo(
		() => settingState.autoplay_enabled && configKeyEnabled(configState, 'autoplay_enabled') && !!autoplayData,
		[settingState.autoplay_enabled, configState, autoplayData]
	)

	useEffect(() => {
		if (!dismissed && overlayStart !== 0) {
			if (currentTime > overlayStart && !showCreditOverlay) {
				setShowCreditOverlay(true)
				onToggleCreditOverlay(autoplayEnabled)
			}

			if (autoplayEnabled) {
				if (currentTime >= overlayEnd && showCreditOverlay) {
					setShowCreditOverlay(false)
					playNextEpisode()
				}
			}
		}
	}, [currentTime, overlayStart, overlayEnd, showCreditOverlay, dismissed])

	useEffect(() => {
		const creditTime = getCreditTime()
		setOverlayStart(creditTime)
		setOverlayEnd(creditTime + Number.parseInt(overlayDuration))
		setDismissed(false)
	}, [overlayDuration, videoDuration])

	const getCreditTime = () => {
		const creditTimeStamp = video?.videoAssets?.[0].timeCodes?.find((o) => o.type === 'END_CREDITS')?.startOffsetSeconds

		const defaultPercentage = 98.6
		const autoplayBuffer = 2
		const popupPercentage =
			getConfigPayloadValue(configState, 'autoplay_enabled', 'overlay_display_percentage') || defaultPercentage
		const popupPercentageSeconds = Math.floor(videoDuration * Number.parseFloat(popupPercentage / 100))

		const defaultStartTime = Math.floor(videoDuration - Number.parseInt(overlayDuration))

		return Math.min(creditTimeStamp || popupPercentageSeconds, defaultStartTime) - autoplayBuffer
	}

	const onDismiss = useCallback(() => {
		setDismissed(true)
		setShowCreditOverlay(false)
	}, [])

	return {
		autoplayEnabled,
		showCreditOverlay,
		remainingDuration: overlayEnd - currentTime,
		onDismiss,
	}
}
