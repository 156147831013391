import styles from './CardChannelSubnav.module.scss'
import { useFocusable } from '@connected-video-web/norigin-spatial-navigation'

export function CardChannelSubnav({ index, item, itemFocus }) {
	const { ref, focused } = useFocusable({
		onFocus: () => {
			itemFocus(ref, item, index)
		},
		focusKey: `SUBMENU_${item?.channelName.toUpperCase()}`,
	})

	return (
		<div ref={ref} className={`${styles.channel_logo_block} ${focused ? styles.active : ''}`}>
			<div className={styles.channel_logo}>
				{item?.channelLogo ? <img src={item?.channelLogo} alt={item?.channelName} /> : <p>{item?.channelName}</p>}
			</div>
		</div>
	)
}
export default CardChannelSubnav
