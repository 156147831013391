import { useSelector } from 'react-redux'

import { NavSidebar } from '@dstv-web-leanback/dstv-frontend-components'

export function Menu(props) {
	const menuItems = useSelector((state) => state.menu.data)
	const playerState = useSelector((state) => state.player)
	const menuSidebar = <NavSidebar items={menuItems} activeProfile={props.activeProfile} />

	return playerState.watchFromStartLive || playerState.backToLive ? null : menuItems && menuSidebar
}

export default Menu
